<template>
  <div class="app">
    <lockdown />
    <header class="header">
      <page-title class="header__title">{{
        $route.meta.title ? $route.meta.title : 'MACKO'
      }}</page-title>
      <logo />
    </header>
    <div class="content">
      <navbar />
      <configurator v-if="model.id" />
      <view-buttons />
    </div>
    <top-tools
      @share="
        showLoadConfiguration = false;
        showQuestionConfiguration = false;
        showShareConfiguration = !showShareConfiguration;
      "
      @load="
        showShareConfiguration = false;
        showQuestionConfiguration = false;
        showLoadConfiguration = !showLoadConfiguration;
      "
      @question="
        showShareConfiguration = false;
        showLoadConfiguration = false;
        showQuestionConfiguration = !showQuestionConfiguration;
      "
      @flip="flipDoor"
      :share="showShareConfiguration"
      :load="showLoadConfiguration"
      :question="showQuestionConfiguration"
    />
    <share
      v-if="showShareConfiguration"
      @load="shareConfiguration"
      @close="closeShareConfiguration"
    />
    <load
      v-if="showLoadConfiguration"
      @load="loadConfiguration"
      @close="closeLoadConfiguration"
    />
    <question
      v-if="showQuestionConfiguration"
      @load="questionConfiguration"
      @close="closeQuestionConfiguration"
    />
    <news-popup v-if="showNewsPopup" @close="hideNews" />
  </div>
</template>

<script>
import '@/assets/reset.scss';
import Navbar from '@/components/navbar/navbar.vue';
import Logo from '@/components/logo/logo.vue';
import Configurator from '@/components/configurator/configurator.vue';
import ViewButtons from '@/components/view/view-buttons.vue';
import Load from '@/components/configurator/load';
import Question from '@/components/configurator/question.vue';
import Share from '@/components/configurator/share';
import TopTools from '@/components/tools/top-tools';
import NewsPopup from '@/components/news/popup';
import PageTitle from '@/components/page-title/page-title';
import Lockdown from '@/components/shared/lockdown';
import { API } from '@/constants/api';
import axios from 'axios';
import { mapGetters } from 'vuex';
import { sanitizeCode } from '@/utils/code';

export default {
  components: {
    Navbar,
    Logo,
    Configurator,
    ViewButtons,
    Load,
    Question,
    Share,
    TopTools,
    NewsPopup,
    PageTitle,
    Lockdown
  },
  data() {
    return {
      showShareConfiguration: false,
      showLoadConfiguration: false,
      showQuestionConfiguration: false,
      showNewsPopup: false
    };
  },
  computed: {
    ...mapGetters('config', [
      'shape',
      'model',
      'glass',
      'color',
      'furniture',
      'furnitureInside',
      'options',
      'inox',
      'view',
      'mirrored',
      'safeglass',
      'house',
      'black',
      'colorThickness',
      'furnitureSubId',
      'furnitureInsideSubId'
    ])
  },
  methods: {
    closeShareConfiguration() {
      return (this.showShareConfiguration = false);
    },
    closeLoadConfiguration() {
      return (this.showLoadConfiguration = false);
    },
    closeQuestionConfiguration() {
      return (this.showQuestionConfiguration = false);
    },
    hideNews() {
      const lastNewsPost = this.$cookies.get('last_news_post');
      this.$cookies.set('current_news_post', lastNewsPost);
      this.$cookies.set('show_news_popup', 'false');

      this.showNewsPopup = false;
    },
    shareConfiguration() {
      this.showShareConfiguration = false;
    },
    loadConfiguration(code) {
      this.showLoadConfiguration = false;
      this.getConfigurationFromCode(code);
      this.$router.push({ query: { code } });
    },
    questionConfiguration() {
      this.showQuestionConfiguration = false;
      alert('question');
    },
    async getDefaultConfiguration() {
      await axios
        .get(API.GET_DEFAULT)
        .then(response =>
          this.$store.dispatch('config/CONFIG_SET_DEFAULTS', response.data)
        )
        .catch(e => {
          this.$swal({
            title: 'Інформація',
            text: e,
            width: 375,
            confirmButtonText: 'OK',
            confirmButtonColor: '#C70552',
            customClass: {
              header: 'sweet-modal__header',
              title: 'sweet-modal__title',
              popup: 'sweet-modal__popup',
              content: 'sweet-modal__content',
              actions: 'sweet-modal__actions',
              confirmButton: 'sweet-modal__ok-button'
            }
          });
        });
      let response;
      const {
        shape,
        model,
        glass,
        color,
        furnitureSubId,
        options,
        inox,
        view,
        mirrored,
        safeglass,
        black,
        colorThickness
      } = this;

      const moldingView = inox?.url.includes('molding')
        ? inox?.url.split('-')[1]
        : null;

      try {
        response = await axios.get(API.GET_CODE_FROM_CONFIG, {
          params: {
            shape: shape.id,
            model: model.id,
            glass: glass.id,
            colour_outside: color.outside.id,
            colour_inside: color.inside.id,
            furniture: furnitureSubId,
            options: options.id,
            inox: inox.id,
            molding: moldingView,
            view: view.id,
            mirrored: mirrored.id,
            safeglass: safeglass.id,
            black,
            colorInsideIs3: colorThickness['inside'] === '2mm' ? '0' : '1',
            colorOutsideIs3: colorThickness['outside'] === '2mm' ? '0' : '1'
          }
        });
      } catch (e) {
        console.error(e);
      }
      await this.$store.dispatch('config/CONFIG_SET_CODE', response.data.code);
      this.$router.push({ query: { code: response.data.code } });
    },
    flipDoor() {
      return this.house === 'outside'
        ? this.$store.dispatch('config/CONFIG_SET_HOUSE_VIEW', 'inside')
        : this.$store.dispatch('config/CONFIG_SET_HOUSE_VIEW', 'outside');
    },
    getConfigurationFromCode(code) {
      code = sanitizeCode(code);
      this.$store.dispatch('config/CONFIG_SET_CODE', code);
      axios
        .get(API.GET_CONFIG_FROM_CODE, {
          params: {
            code
          }
        })
        .then(response => {
          if (response.data.shape) {
            this.$store.dispatch('config/CONFIG_SET_DEFAULTS', response.data);
            if (response.data.messages?.length) {
              this.$swal({
                title: 'Інформація',
                html: response.data.messages.join('<br />'),
                width: 375,
                confirmButtonText: 'OK',
                confirmButtonColor: '#C70552',
                customClass: {
                  header: 'sweet-modal__header',
                  title: 'sweet-modal__title',
                  popup: 'sweet-modal__popup',
                  content: 'sweet-modal__content',
                  actions: 'sweet-modal__actions',
                  confirmButton: 'sweet-modal__ok-button'
                }
              });
            }
          } else {
            this.$swal({
              title: 'Інформація',
              text: 'Код конфігурації не знайдено',
              width: 375,
              confirmButtonText: 'OK',
              confirmButtonColor: '#C70552',
              customClass: {
                header: 'sweet-modal__header',
                title: 'sweet-modal__title',
                popup: 'sweet-modal__popup',
                content: 'sweet-modal__content',
                actions: 'sweet-modal__actions',
                confirmButton: 'sweet-modal__ok-button'
              }
            }).then(() => {
              this.$router.push({
                name: 'Config'
              });
              return this.getDefaultConfiguration();
            });
          }
        });
    }
  },
  beforeMount() {
    const currentLang = this.$store.getters['session/lang'];
    this.$store.dispatch('session/SET_LANGUAGE', currentLang);
  },
  async beforeCreate() {
    axios.interceptors.response.use(
      response => {
        return response;
      },
      async error => {
        const config = error.config;

        if (
          error.response.status === 401 &&
          config &&
          !config.__isRetryRequest
        ) {
          await this.$store.dispatch('auth/AUTH_LOGOUT');
          await this.$router.push({ name: 'Login' });
        }

        return Promise.reject(error);
      }
    );

    axios.interceptors.request.use(request => {
      const token = localStorage.getItem('user-token');
      if (!request.headers.Authorization && token) {
        request.headers.Authorization = `Bearer ${token}`;
      }
      return request;
    });
  },
  mounted() {
    this.showNewsPopup = this.$cookies.get('show_news_popup') !== 'false';
    const lastNewsPost = this.$cookies.get('last_news_post');
    const currentNewsPost = this.$cookies.get('current_news_post');
    if (lastNewsPost !== currentNewsPost) {
      this.showNewsPopup = true;
    }

    const uri = window.location.href.split('?');
    let code = null;
    if (uri.length === 2) {
      if (uri[1].split('=')[1].length) {
        code = uri[1].split('=')[1];
      }
    }

    if (code !== null) {
      return this.getConfigurationFromCode(code);
    } else {
      return this.getDefaultConfiguration();
    }
  }
};
</script>

<style lang="scss">
.app {
  flex: 1;
  @media screen and (min-width: 960px) {
    display: flex;
    flex-direction: column;
  }
}
.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 4;
  @include miw($xl) {
    position: static;
    left: auto;
    top: auto;
    width: auto;
    flex: 1 0 90px;
    max-height: 90px;
    display: flex;
    z-index: 1;
  }
  &__title {
    flex: 1;
    height: 50px;
    .page-title__close {
      display: none;
    }
    @include miw($xl) {
      display: none;
    }
  }
}
.content {
  flex: 1;
  @media screen and (min-width: 960px) {
    display: flex;
  }
}
</style>

<style lang="scss">
.header,
.navbar__opener {
  top: 29px;
}

.navbar__list {
  top: 79px;
}

.price-label {
  right: 6px;
  top: 85px;
  font: 22px/30px ProximaNovaBold, sans-serif;
  padding: 6px;
  @include miw($xl) {
    top: 8px;
  }
}

.top-tools {
  @include miw($xl) {
    top: 26px;
  }
}
</style>
