<template>
  <div class="configurator" :style="flipHouse">
    <div class="configurator__wrapper">
      <img
        class="configurator__img"
        :src="
          house === 'outside'
            ? '../../cameraview/door_notree_and_windows.jpg'
            : '../../cameraview/interior.jpg'
        "
        alt=""
      />
      <door
        id="svgObject1"
        :key="doorKey1"
        v-if="model.id"
        :model="model.url"
        :handle="furniture.url"
        :double="shape.double_door"
        :totalWidth="doorTotalWidth"
        :totalHeight="doorTotalHeight"
        :doorWidth="doorWidth"
        :doorHeight="doorHeight"
        :doorLeftWidth="doorLeftWidth"
        :doorRightWidth="doorRightWidth"
        :doorTopHeight="doorTopHeight"
        :options="options.construction_type"
        :doorMirrorType="mirrored.mirrored_type"
        :style="{
          width: `${doorTotalWidth / 10}vw`,
          height: `${doorTotalHeight / 10}vw`
        }"
        :class="[
          'configurator__door',
          'configurator__door--outside',
          { 'configurator__door--hide': house === 'inside' },
          {
            'configurator__door--standard':
              mirrored.mirrored_type === 'standard'
          },
          {
            'configurator__door--mirror': mirrored.mirrored_type === 'mirrored'
          }
        ]"
        :doorViewType="view"
        :doorInoxType="inox"
        :doorStrokeColor="color[house].stroke"
        :doorSolidColor="color[house].colour_prevailing"
        :doorBackgroundImage="outdoorBackgroundImage"
        :handleColor="color[house].colour"
        :doorBackgroundWidth="color[house].pattern_width"
        :doorBackgroundHeight="color[house].pattern_height"
        :doorBackgroundColor="outdoorBackgroundColor"
        :doorGlassImage="glass.image_pattern"
        :customGlass="glass.repeat_pattern === '0'"
        :doorGlassWidth="glass.pattern_width"
        :doorGlassHeight="glass.pattern_height"
        :doorGlassSandBlasting="glass.sandblasting"
        :doorInox="glass.inox"
        :houseView="house"
        :isBlack="isBlackOutside"
      />
      <door
        id="svgObject2"
        :key="doorKey2"
        v-if="model.id"
        :model="model.url"
        :handle="furnitureInside.url"
        :double="shape.double_door"
        :totalWidth="doorTotalWidth"
        :totalHeight="doorTotalHeight"
        :doorWidth="doorWidth"
        :doorHeight="doorHeight"
        :doorLeftWidth="doorLeftWidth"
        :doorRightWidth="doorRightWidth"
        :doorTopHeight="doorTopHeight"
        :options="options.construction_type"
        :doorMirrorType="mirrored.mirrored_type"
        :style="{
          width: `${(doorTotalWidth / 11) * 1.12}vw`,
          height: `${(doorTotalHeight / 11) * 1.12}vw`
        }"
        :class="[
          'configurator__door',
          'configurator__door--inside',
          { 'configurator__door--hide': house === 'outside' },
          {
            'configurator__door--standard':
              mirrored.mirrored_type === 'standard'
          },
          {
            'configurator__door--mirror': mirrored.mirrored_type === 'mirrored'
          }
        ]"
        :doorViewType="view"
        :doorInoxType="inox"
        :doorStrokeColor="color[house].stroke"
        :doorSolidColor="color[house].colour_prevailing"
        :doorBackgroundImage="indoorBackgroundImage"
        :handleColor="color[house].colour"
        :doorBackgroundWidth="color[house].pattern_width"
        :doorBackgroundHeight="color[house].pattern_height"
        :doorBackgroundColor="indoorBackgroundColor"
        :doorGlassImage="glass.image_pattern"
        :customGlass="glass.repeat_pattern === '0'"
        :doorGlassWidth="glass.pattern_width"
        :doorGlassHeight="glass.pattern_height"
        :doorGlassSandBlasting="glass.sandblasting"
        :doorInox="glass.inox"
        :houseView="house"
        :isBlack="isBlackInside"
      />
    </div>
    <price :key="calcKey" />
    <transition name="slide">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Price from '@/components/price/price.vue';
import { mapGetters } from 'vuex';
import Door from '@/components/door/model';

export default {
  computed: {
    isBlackInside() {
      if (this.black === 'both') {
        return true;
      }
      return this.black === 'inside' && this.house === 'inside';
    },
    isBlackOutside() {
      if (this.black === 'both') {
        return true;
      }
      return this.black === 'outside' && this.house === 'outside';
    },
    // store
    ...mapGetters('config', [
      'code',
      'shape',
      'model',
      'glass',
      'color',
      'furniture',
      'options',
      'inox',
      'safeglass',
      'view',
      'mirrored',
      'house',
      'calculate',
      'dimensions',
      'black',
      'furnitureInside',
      'furnitureSub'
    ]),
    // door dimensions
    doorTotalWidth() {
      // do not use coefficient here, it's already applied for each addend accordingly
      return this.doorWidth + this.doorLeftWidth + this.doorRightWidth;
    },
    doorTotalHeight() {
      // do not use coefficient here, it's already applied for each addend accordingly
      return this.doorHeight + this.doorTopHeight;
    },
    doorWidth() {
      return this.dimensions.width * this.drawingCoefficient;
    },
    doorHeight() {
      return this.dimensions.height * this.drawingCoefficient;
    },
    doorLeftWidth() {
      return this.shape.glass_left
        ? this.dimensions.leftWidth * this.drawingCoefficient
        : 0;
    },
    doorRightWidth() {
      return this.shape.glass_right
        ? this.dimensions.rightWidth * this.drawingCoefficient
        : 0;
    },
    doorTopHeight() {
      return this.shape.glass_top
        ? this.dimensions.topHeight * this.drawingCoefficient
        : 0;
    },
    // redrawing
    doorKey1() {
      return `door_outside_${this.code}${this.model.id}${this.furniture.url}${
        this.house
      }${this.color.type}${
        this.color['outside'].pattern_svg
          ? this.color['outside'].pattern_svg
          : ''
      }${this.color['outside'].colour}${this.color['outside'].stroke}${
        this.view.view_type
      }${this.mirrored.mirrored_type}${this.showInox}_${this.inox.black}`;
    },
    doorKey2() {
      return `door_inside_${this.code}${this.model.id}${
        this.furnitureInside.url
      }${this.house}${this.color.type}${
        this.color['inside'].pattern_svg ? this.color['inside'].pattern_svg : ''
      }${this.color['inside'].colour}${this.color['inside'].stroke}${
        this.view.view_type
      }${this.mirrored.mirrored_type}${this.showInox}_${this.inox.black}`;
    },
    calcKey() {
      return `calc_${this.shape.id}_${this.model.id}_${this.glass.id}_${this.color.outside.id}_${this.color.inside.id}_${this.furniture.id}_${this.options.id}_${this.mirrored.id}_${this.inox.id}_${this.inox.black}_${this.safeglass.id}_${this.black}_${this.furnitureInside.id}_${this.furnitureSub.id}`;
    },
    // options
    showInox() {
      return (
        !(this.inox.inox_type === 'inox_one_side') &&
        this.inox.inox_type !== 'inox_no'
      );
    },
    priceKey() {
      return `${this.model.id}`;
    },
    flipHouse() {
      return this.house === 'outside'
        ? 'background-image: url(../../cameraview/door_notree_and_windows.jpg);'
        : 'background-image: url(../../cameraview/interior.jpg?ver=20210302);';
    },
    outdoorBackgroundImage() {
      return this.color['outside'].pattern_svg || '';
    },
    indoorBackgroundImage() {
      return this.color['inside'].pattern_svg || '';
    },
    outdoorBackgroundColor() {
      if (this.color.type === 'complete' || this.color.type === 'outside') {
        return this.color[this.house].colour;
      }
      return '#fff';
    },
    indoorBackgroundColor() {
      if (this.color.type === 'complete' || this.color.type === 'inside') {
        return this.color[this.house].colour;
      }
      return '#fff';
    }
  },
  data() {
    return {
      drawingCoefficient: 0.143, // transform door size into a drawable SVG
      handleGradients: [
        {
          offset: '5%',
          stopColor: '#7c7e80'
        },
        {
          offset: '20%',
          stopColor: '#b5b5b5'
        },
        {
          offset: '40%',
          stopColor: '#f8f8f9'
        },
        {
          offset: '66%',
          stopColor: '#bfbfc1'
        },
        {
          offset: '95%',
          stopColor: '#656567'
        }
      ]
    };
  },
  components: {
    Price,
    Door
  }
};
</script>

<style lang="scss">
.configurator {
  background-size: 0 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 40px;
  @include miw($xl) {
    display: block;
    position: relative;
    padding-bottom: 0;
    background-color: #e5e6e0;
    background: {
      repeat: no-repeat;
      position: 50% 100%;
      size: 100% auto;
    }
  }
  &__img {
    display: block;
    width: 100%;
    @include miw($xl) {
      display: none;
    }
  }
  &__wrapper {
    position: relative;
    margin-top: 50px;
    @include miw($xl) {
      position: static;
      margin-top: 0;
    }
  }
  &__door {
    position: absolute;
    &--hide {
      left: -100vw !important;
    }
    &--outside {
      bottom: 8vw;
      @include miw(375) {
        bottom: 8.5vw;
      }
      @include miw(450) {
        bottom: 8.7vw;
      }
      @include miw(768) {
        bottom: 9.1vw;
      }
      @include miw(1025) {
        bottom: 7.2vw;
      }
      @include miw(1200) {
        bottom: 7.6vw;
      }
      @include miw(1300) {
        bottom: 7.7vw;
      }
      @include miw(1400) {
        bottom: 7.85vw;
      }
      @include miw(1500) {
        bottom: 8vw;
      }
      @include miw(1600) {
        bottom: 8.1vw;
      }
      @include miw(1800) {
        bottom: 8.2vw;
      }
      @include miw(1920) {
        bottom: 8.35vw;
      }
    }
    &--inside {
      bottom: 8.3vw;
    }
    @include miw($xl) {
      width: 14.3vw;
      height: 29.8vw;
      &--outside,
      &--inside {
        bottom: 8vw;
      }
    }
    &--outside {
      left: 50vw;
      @include miw($xl) {
        left: 50%;
      }
      &.configurator__door--standard {
        transform: scaleX(1) translate(-50%, 0);
      }
      &.configurator__door--mirror {
        transform: scaleX(-1) translate(50%, 0);
      }
    }
    &--inside {
      left: 50vw;
      @include miw($xl) {
        left: auto;
        right: 36vw;
        margin-top: 1vw;
      }
      &.configurator__door--standard {
        transform: scaleX(-1) translate(50%, 0);
        @include miw($xl) {
          transform: scaleX(-1) translate(-50%, 0);
        }
      }
      &.configurator__door--mirror {
        transform: scaleX(1) translate(-50%, 0);
        @include miw($xl) {
          transform: scaleX(1) translate(50%, 0);
        }
      }
    }
  }
}

@include miw($xl) {
  .slide-leave-active,
  .slide-enter-active {
    transition: transform 0.5s;
  }
  .slide-enter {
    transform: translate(-100%, 0);
  }
  .slide-leave-to {
    transform: translate(-100%, 0);
  }
}
</style>
