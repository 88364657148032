export const CONFIG_SET_DEFAULTS = 'CONFIG_SET_DEFAULTS';
export const CONFIG_SET_CODE = 'CONFIG_SET_CODE';
export const CONFIG_SET_SHAPE = 'CONFIG_SET_SHAPE';
export const CONFIG_SET_SERIES = 'CONFIG_SET_SERIES';
export const CONFIG_SET_MODEL = 'CONFIG_SET_MODEL';
export const CONFIG_SET_GLASS = 'CONFIG_SET_GLASS';
export const CONFIG_SET_HOUSE_VIEW = 'CONFIG_SET_HOUSE_VIEW';
export const CONFIG_SET_COLOR_TYPE = 'CONFIG_SET_COLOR_TYPE';
export const CONFIG_SET_COLOR_OUTSIDE = 'CONFIG_SET_COLOR_OUTSIDE';
export const CONFIG_SET_COLOR_INSIDE = 'CONFIG_SET_COLOR_INSIDE';
export const CONFIG_SET_FURNITURE = 'CONFIG_SET_FURNITURE';
export const CONFIG_SET_OPTIONS = 'CONFIG_SET_OPTIONS';
export const CONFIG_SET_CONSTRUCTION = 'CONFIG_SET_CONSTRUCTION';
export const CONFIG_SET_INOX = 'CONFIG_SET_INOX';
export const CONFIG_SET_VIEW = 'CONFIG_SET_VIEW';
export const CONFIG_SET_MIRRORED = 'CONFIG_SET_MIRRORED';
export const CONFIG_SET_SAFE_GLASS = 'CONFIG_SET_SAFE_GLASS';
export const CONFIG_ALLOW_CALCULATE = 'CONFIG_ALLOW_CALCULATE';
export const CONFIG_SET_WIDTH = 'CONFIG_SET_WIDTH';
export const CONFIG_SET_HEIGHT = 'CONFIG_SET_HEIGHT';
export const CONFIG_SET_TOP_HEIGHT = 'CONFIG_SET_TOP_HEIGHT';
export const CONFIG_SET_LEFT_WIDTH = 'CONFIG_SET_LEFT_WIDTH';
export const CONFIG_SET_RIGHT_WIDTH = 'CONFIG_SET_RIGHT_WIDTH';
export const CONFIG_SET_BLACK = 'CONFIG_SET_BLACK';
export const CONFIG_SET_FURNITURE_INSIDE = 'CONFIG_SET_FURNITURE_INSIDE';
export const CONFIG_SET_COLOR_THICKNESS_OUTSIDE =
  'CONFIG_SET_COLOR_THICKNESS_OUTSIDE';
export const CONFIG_SET_COLOR_THICKNESS_INSIDE =
  'CONFIG_SET_COLOR_THICKNESS_INSIDE';
export const CONFIG_SET_COLOR_INSIDE_SUB_ID = 'CONFIG_SET_COLOR_INSIDE_SUB_ID';
export const CONFIG_SET_COLOR_OUTSIDE_SUB_ID =
  'CONFIG_SET_COLOR_OUTSIDE_SUB_ID';
export const CONFIG_SET_FORM_SUB_ID = 'CONFIG_SET_FORM_SUB_ID';
export const CONFIG_SET_FURNITURE_SUB = 'CONFIG_SET_FURNITURE_SUB';
export const CONFIG_SET_FURNITURE_INSIDE_SUB_ID =
  'CONFIG_SET_FURNITURE_INSIDE_SUB_ID';
