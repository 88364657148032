<template>
  <g>
    <svg:style>
      .cls-1 {
        isolation: isolate;
      }

      .cls-2,
      .cls-3 .cls-24 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }
      .cls-27 {
        fill: url(#Bottom_Gradient_2);
      }

      .cls-3 {
        fill: none;
      }
      .cls-5 {
        fill: url(#radial-gradient);
      }

      .cls-24 {
        fill: url(#glass-pattern);
      }
      .cls-4 {
        opacity: 0.5;
        mix-blend-mode: multiply;
      }

      .cls-5 {
        fill: url(#radial-gradient);
      }

      .cls-7 {
        fill: url(#LR_Gradient_2);
      }

      .cls-8 {
        fill: url(#LR_Gradient_2-2);
      }

      .cls-9 {
        fill: url(#Top_Gradient_2);
      }

       .Solid { fill: {{ doorSolidColor }} }
      .Molding-L { fill: url(#Molding-L); } /* left */
      .Molding-T { fill: url(#Molding-T); } /* top */
      .Molding-R { fill: url(#Molding-R); } /* right */
      .Molding-B { fill: url(#Molding-B); } /* bottom */
    </svg:style>
    <linearGradient
      id="BG-gradient"
      x1="70.12"
      y1="0.12"
      x2="70.13"
      y2="294.12"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.25" stop-color="#f9f9f9" />
      <stop offset="0.67" stop-color="#e8e8e8" />
      <stop offset="0.79" stop-color="#e2e2e2" />
    </linearGradient>
    <radialGradient
      id="radial-gradient"
      cx="14"
      cy="146.78"
      r="3.7"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#977c00" />
      <stop offset="0.5" stop-color="#956f00" />
      <stop offset="0.89" stop-color="#8d5e00" />
      <stop offset="1" stop-color="#cc8d00" />
    </radialGradient>
    <linearGradient
      id="Bottom_Gradient_2"
      data-name="Bottom Gradient 2"
      x1="-862.65"
      y1="7734.67"
      x2="-862.65"
      y2="7726.17"
      gradientTransform="translate(-793.03 7981.89) rotate(180)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#fff" stop-opacity="0.05" />
      <stop offset="0.05" stop-opacity="0.5" />
      <stop offset="0.06" stop-opacity="0.5" />
      <stop offset="0.58" stop-opacity="0.3" />
      <stop offset="0.87" stop-opacity="0.3" />
      <stop offset="0.89" stop-opacity="0.3" />
      <stop offset="1" stop-opacity="0.6" />
    </linearGradient>
    <linearGradient
      id="LR_Gradient_2"
      data-name="LR Gradient 2"
      x1="-128.91"
      y1="9542.53"
      x2="-128.91"
      y2="9534.01"
      gradientTransform="translate(-9499.83 98.11) rotate(-90)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#fff" stop-opacity="0.3" />
      <stop offset="0.01" stop-color="#e9e9e9" stop-opacity="0.3" />
      <stop offset="0.02" stop-color="#ababab" stop-opacity="0.3" />
      <stop offset="0.03" stop-color="#454545" stop-opacity="0.3" />
      <stop offset="0.04" stop-opacity="0.3" />
      <stop offset="0.58" stop-opacity="0.1" />
      <stop offset="0.87" stop-opacity="0.28" />
      <stop offset="0.89" stop-opacity="0.3" />
      <stop offset="1" stop-opacity="0.6" />
    </linearGradient>
    <linearGradient
      id="LR_Gradient_2-2"
      x1="96.5"
      y1="227.02"
      x2="104.89"
      y2="227.02"
      gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
      xlink:href="#LR_Gradient_2"
    />
    <linearGradient
      id="BG-gradient"
      x1="70.12"
      y1="0.12"
      x2="70.13"
      y2="294.12"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.25" stop-color="#f9f9f9" />
      <stop offset="0.67" stop-color="#e8e8e8" />
      <stop offset="0.79" stop-color="#e2e2e2" />
    </linearGradient>
    <radialGradient
      id="radial-gradient"
      cx="14"
      cy="146.78"
      r="3.7"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#977c00" />
      <stop offset="0.5" stop-color="#956f00" />
      <stop offset="0.89" stop-color="#8d5e00" />
      <stop offset="1" stop-color="#cc8d00" />
    </radialGradient>
    <linearGradient
      id="glass-gradient"
      x1="47.95"
      y1="181.16"
      x2="47.95"
      y2="40.87"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#f4f4f4" />
      <stop offset="0.5" stop-color="#dee8e8" />
      <stop offset="1" stop-color="#d1cece" />
    </linearGradient>
    <linearGradient
      id="glass-gradient-2"
      data-name="glass-gradient"
      x1="92.11"
      y1="181.16"
      x2="92.11"
      y2="40.92"
      xlink:href="#glass-gradient"
    />
    <linearGradient
      id="Bottom_Gradient_2"
      data-name="Bottom Gradient 2"
      x1="-862.65"
      y1="7411"
      x2="-862.65"
      y2="7402.5"
      gradientTransform="translate(-793.03 7658.22) rotate(180)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#fff" stop-opacity="0.05" />
      <stop offset="0.05" stop-opacity="0.5" />
      <stop offset="0.06" stop-opacity="0.5" />
      <stop offset="0.58" stop-opacity="0.3" />
      <stop offset="0.87" stop-opacity="0.3" />
      <stop offset="0.89" stop-opacity="0.3" />
      <stop offset="1" stop-opacity="0.6" />
    </linearGradient>
    <linearGradient
      id="LR_Gradient_2"
      data-name="LR Gradient 2"
      x1="-452.57"
      y1="9542.53"
      x2="-452.57"
      y2="9534.01"
      gradientTransform="translate(-9499.83 -225.56) rotate(-90)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#fff" stop-opacity="0.3" />
      <stop offset="0.01" stop-color="#e9e9e9" stop-opacity="0.3" />
      <stop offset="0.02" stop-color="#ababab" stop-opacity="0.3" />
      <stop offset="0.03" stop-color="#454545" stop-opacity="0.3" />
      <stop offset="0.04" stop-opacity="0.3" />
      <stop offset="0.58" stop-opacity="0.1" />
      <stop offset="0.87" stop-opacity="0.28" />
      <stop offset="0.89" stop-opacity="0.3" />
      <stop offset="1" stop-opacity="0.6" />
    </linearGradient>
    <linearGradient
      id="LR_Gradient_2-2"
      x1="96.5"
      y1="227.02"
      x2="104.89"
      y2="227.02"
      gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
      xlink:href="#LR_Gradient_2"
    />
    <linearGradient
      id="Top_Gradient_2"
      data-name="Top Gradient 2"
      x1="69.53"
      y1="207.14"
      x2="69.53"
      y2="198.32"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#fff" stop-opacity="0.6" />
      <stop offset="0" stop-color="#fff" stop-opacity="0.56" />
      <stop offset="0" stop-color="#fff" stop-opacity="0.44" />
      <stop offset="0.01" stop-color="#fff" stop-opacity="0.33" />
      <stop offset="0.01" stop-color="#fff" stop-opacity="0.25" />
      <stop offset="0.02" stop-color="#fff" stop-opacity="0.18" />
      <stop offset="0.03" stop-color="#fff" stop-opacity="0.13" />
      <stop offset="0.04" stop-color="#fff" stop-opacity="0.11" />
      <stop offset="0.06" stop-color="#fff" stop-opacity="0.1" />
      <stop offset="0.26" stop-color="#fff" stop-opacity="0.04" />
      <stop offset="0.47" stop-color="#fff" stop-opacity="0" />
      <stop offset="0.87" stop-opacity="0.2" />
      <stop offset="1" stop-opacity="0.5" />
    </linearGradient>
    <linearGradient
      id="New_Gradient_Swatch_3"
      data-name="New Gradient Swatch 3"
      x1="47.73"
      y1="36.48"
      x2="47.73"
      y2="40.92"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#979695" />
      <stop offset="0.34" stop-color="#fff" />
      <stop offset="0.61" stop-color="#5f5d59" />
      <stop offset="0.7" stop-color="#a0a0a0" />
      <stop offset="0.77" stop-color="#5f5d59" />
      <stop offset="0.86" stop-color="#fff" />
      <stop offset="1" stop-color="#a6a8aa" />
    </linearGradient>
    <linearGradient
      id="New_Gradient_Swatch_3-2"
      x1="-1824.2"
      y1="7394.6"
      x2="-1824.2"
      y2="7399.03"
      gradientTransform="translate(-1776.4 7580.19) rotate(180)"
      xlink:href="#New_Gradient_Swatch_3"
    />
    <linearGradient
      id="New_Gradient_Swatch_3-3"
      x1="-578.1"
      y1="10272.83"
      x2="-578.1"
      y2="10277.88"
      gradientTransform="translate(-10243.53 -467.06) rotate(-90)"
      xlink:href="#New_Gradient_Swatch_3"
    />
    <linearGradient
      id="New_Gradient_Swatch_3-4"
      x1="1002.3"
      y1="6228.04"
      x2="1002.3"
      y2="6233.09"
      gradientTransform="translate(6294.34 -891.27) rotate(90)"
      xlink:href="#New_Gradient_Swatch_3"
    />
    <linearGradient
      id="New_Gradient_Swatch_3-5"
      x1="92.04"
      y1="36.48"
      x2="92.04"
      y2="40.92"
      xlink:href="#New_Gradient_Swatch_3"
    />
    <linearGradient
      id="New_Gradient_Swatch_3-6"
      x1="-1868.42"
      y1="7394.6"
      x2="-1868.42"
      y2="7399.03"
      gradientTransform="translate(-1776.4 7580.19) rotate(180)"
      xlink:href="#New_Gradient_Swatch_3"
    />
    <linearGradient
      id="New_Gradient_Swatch_3-7"
      x1="-578.23"
      y1="10317.8"
      x2="-578.23"
      y2="10322.75"
      gradientTransform="translate(-10243.53 -467.06) rotate(-90)"
      xlink:href="#New_Gradient_Swatch_3"
    />
    <linearGradient
      id="New_Gradient_Swatch_3-8"
      x1="1002.3"
      y1="6184.53"
      x2="1002.3"
      y2="6189.48"
      gradientTransform="translate(6294.34 -891.27) rotate(90)"
      xlink:href="#New_Gradient_Swatch_3"
    />
    <linearGradient
      id="New_Gradient_Swatch_3-9"
      x1="69.53"
      y1="193.88"
      x2="69.53"
      y2="198.32"
      xlink:href="#New_Gradient_Swatch_3"
    />
    <linearGradient
      id="New_Gradient_Swatch_3-10"
      x1="-1845.94"
      y1="7320.04"
      x2="-1845.94"
      y2="7324.48"
      gradientTransform="translate(-1776.4 7580.19) rotate(180)"
      xlink:href="#New_Gradient_Swatch_3"
    />
    <linearGradient
      id="New_Gradient_Swatch_3-11"
      x1="-694.08"
      y1="10272.83"
      x2="-694.08"
      y2="10277.88"
      gradientTransform="translate(-10243.53 -467.06) rotate(-90)"
      xlink:href="#New_Gradient_Swatch_3"
    />
    <linearGradient
      id="New_Gradient_Swatch_3-12"
      x1="1118.28"
      y1="6184.57"
      x2="1118.28"
      y2="6189.44"
      gradientTransform="translate(6294.34 -891.27) rotate(90)"
      xlink:href="#New_Gradient_Swatch_3"
    />
    <linearGradient
      id="Top_Gradient_2"
      data-name="Top Gradient 2"
      x1="69.53"
      y1="207.14"
      x2="69.53"
      y2="198.32"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#fff" stop-opacity="0.6" />
      <stop offset="0" stop-color="#fff" stop-opacity="0.56" />
      <stop offset="0" stop-color="#fff" stop-opacity="0.44" />
      <stop offset="0.01" stop-color="#fff" stop-opacity="0.33" />
      <stop offset="0.01" stop-color="#fff" stop-opacity="0.25" />
      <stop offset="0.02" stop-color="#fff" stop-opacity="0.18" />
      <stop offset="0.03" stop-color="#fff" stop-opacity="0.13" />
      <stop offset="0.04" stop-color="#fff" stop-opacity="0.11" />
      <stop offset="0.06" stop-color="#fff" stop-opacity="0.1" />
      <stop offset="0.26" stop-color="#fff" stop-opacity="0.04" />
      <stop offset="0.47" stop-color="#fff" stop-opacity="0" />
      <stop offset="0.87" stop-opacity="0.2" />
      <stop offset="1" stop-opacity="0.5" />
    </linearGradient>
    <linearGradient
      id="Molding-L"
      data-name="Molding"
      x1="0"
      y1="0"
      x2="100%"
      y2="0"
    >
      <stop offset="0" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4" />
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1" />
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4" />
      <stop offset="1" stop-color="#000" stop-opacity="0.5" />
    </linearGradient>
    <linearGradient
      id="Molding-T"
      data-name="Molding"
      x1="0"
      y1="0"
      x2="0"
      y2="100%"
    >
      <stop offset="0" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4" />
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1" />
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4" />
      <stop offset="1" stop-color="#000" stop-opacity="0.5" />
    </linearGradient>
    <linearGradient
      id="Molding-R"
      data-name="Molding"
      x1="100%"
      y1="0"
      x2="0"
      y2="0"
    >
      <stop offset="0" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4" />
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1" />
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4" />
      <stop offset="1" stop-color="#000" stop-opacity="0.5" />
    </linearGradient>
    <linearGradient
      id="Molding-B"
      data-name="Molding"
      x1="0"
      y1="100%"
      x2="0"
      y2="0"
    >
      <stop offset="0" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4" />
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1" />
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4" />
      <stop offset="1" stop-color="#000" stop-opacity="0.5" />
    </linearGradient>
    <g class="cls-1">
      <g id="Models_summer_2023" data-name="Models summer 2023">
        <g id="С21_new">
          <g id="Panel_B" data-name="Panel B">
            <rect
              id="glass"
              class="cls-24"
              :x="`${doorLeftWidth1 + 34.47}`"
              :y="`${doorTopHeight1 + 40.87}`"
              :width="`${61.42 - 34.47}`"
              :height="`${181.16 - 40.87}`"
            />
            <rect
              id="glass-2"
              class="cls-24"
              :x="`${doorLeftWidth1 + 78.58}`"
              :y="`${doorTopHeight1 + 40.91}`"
              :width="`${105.64 - 78.58}`"
              :height="`${181.16 - 40.91}`"
            />
            <polygon
              id="Shadow"
              class="cls-27"
              :points="
                `${doorLeftWidth1 + 42.7} ${doorTopHeight1 +
                  247.22} ${doorLeftWidth1 + 96.5} ${doorTopHeight1 +
                  247.22} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                  255.72} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                  255.72} ${doorLeftWidth1 + 42.7} ${doorTopHeight1 + 247.22}`
              "
            />
            <polygon
              id="Panel_L"
              data-name="Panel L"
              class="cls-7"
              :points="
                `${doorLeftWidth1 + 42.7} ${doorTopHeight1 +
                  207.15} ${doorLeftWidth1 + 42.7} ${doorTopHeight1 +
                  247.22} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                  255.72} ${doorLeftWidth1 + 34.17} ${doorTopHeight1 +
                  198.32} ${doorLeftWidth1 + 42.7} ${doorTopHeight1 + 207.15}`
              "
            />
            <polygon
              id="Panel_R"
              data-name="Panel R"
              class="cls-8"
              :points="
                `${doorLeftWidth1 + 96.5} ${doorTopHeight1 +
                  206.87} ${doorLeftWidth1 + 96.5} ${doorTopHeight1 +
                  247.22} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                  255.72} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                  198.32} ${doorLeftWidth1 + 96.5} ${doorTopHeight1 + 206.87}`
              "
            />
            <polygon
              id="Highlight"
              class="cls-9"
              :points="
                `${doorLeftWidth1 + 96.5} ${doorTopHeight1 +
                  206.86} ${doorLeftWidth1 + 42.7} ${doorTopHeight1 +
                  207.15} ${doorLeftWidth1 + 34.17} ${doorTopHeight1 +
                  198.32} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                  198.32} ${doorLeftWidth1 + 96.5} ${doorTopHeight1 + 206.86}`
              "
            />
          </g>
          <g id="Moldings" v-if="showMolding">
            <g id="Molding_RT" data-name="Molding RT">
              <polygon
                class="Solid"
                :points="
                  `${doorLeftWidth1 + 61.25} ${doorTopHeight1 +
                    40.91} ${doorLeftWidth1 + 34.17} ${doorTopHeight1 +
                    40.91} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                    36.48} ${doorLeftWidth1 + 66.3} ${doorTopHeight1 +
                    36.48} ${doorLeftWidth1 + 61.25} ${doorTopHeight1 + 40.91}`
                "
              />
              <polygon
                class="Solid"
                :points="
                  `${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                    181.16} ${doorLeftWidth1 + 61.42} ${doorTopHeight1 +
                    181.16} ${doorLeftWidth1 + 66.3} ${doorTopHeight1 +
                    185.6} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                    185.6} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 + 181.16}`
                "
              />
              <polygon
                class="Solid"
                :points="
                  `${doorLeftWidth1 + 34.17} ${doorTopHeight1 +
                    40.91} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                    181.16} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                    185.6} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                    36.48} ${doorLeftWidth1 + 34.17} ${doorTopHeight1 + 40.91}`
                "
              />
              <polygon
                class="Solid"
                :points="
                  `${doorLeftWidth1 + 61.42} ${doorTopHeight1 +
                    181.16} ${doorLeftWidth1 + 61.25} ${doorTopHeight1 +
                    40.91} ${doorLeftWidth1 + 66.3} ${doorTopHeight1 +
                    36.48} ${doorLeftWidth1 + 66.3} ${doorTopHeight1 +
                    185.6} ${doorLeftWidth1 + 61.42} ${doorTopHeight1 + 181.16}`
                "
              />
              <polygon
                class="Molding-T"
                :points="
                  `${doorLeftWidth1 + 61.25} ${doorTopHeight1 +
                    40.91} ${doorLeftWidth1 + 34.17} ${doorTopHeight1 +
                    40.91} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                    36.48} ${doorLeftWidth1 + 66.3} ${doorTopHeight1 +
                    36.48} ${doorLeftWidth1 + 61.25} ${doorTopHeight1 + 40.91}`
                "
              />
              <polygon
                class="Molding-B"
                :points="
                  `${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                    181.16} ${doorLeftWidth1 + 61.42} ${doorTopHeight1 +
                    181.16} ${doorLeftWidth1 + 66.3} ${doorTopHeight1 +
                    185.6} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                    185.6} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 + 181.16}`
                "
              />
              <polygon
                class="Molding-L"
                :points="
                  `${doorLeftWidth1 + 34.17} ${doorTopHeight1 +
                    40.91} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                    181.16} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                    185.6} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                    36.48} ${doorLeftWidth1 + 34.17} ${doorTopHeight1 + 40.91}`
                "
              />
              <polygon
                class="Molding-R"
                :points="
                  `${doorLeftWidth1 + 61.42} ${doorTopHeight1 +
                    181.16} ${doorLeftWidth1 + 61.25} ${doorTopHeight1 +
                    40.91} ${doorLeftWidth1 + 66.3} ${doorTopHeight1 +
                    36.48} ${doorLeftWidth1 + 66.3} ${doorTopHeight1 +
                    185.6} ${doorLeftWidth1 + 61.42} ${doorTopHeight1 + 181.16}`
                "
              />
            </g>
            <g id="Molding_LT" data-name="Molding LT">
              <polygon
                class="Solid"
                :points="
                  `${doorLeftWidth1 + 104.72} ${doorTopHeight1 +
                    40.91} ${doorLeftWidth1 + 77.64} ${doorTopHeight1 +
                    40.91} ${doorLeftWidth1 + 72.77} ${doorTopHeight1 +
                    36.48} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                    36.48} ${doorLeftWidth1 + 104.72} ${doorTopHeight1 + 40.91}`
                "
              />
              <polygon
                class="Solid"
                :points="
                  `${doorLeftWidth1 + 77.82} ${doorTopHeight1 +
                    181.16} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                    181.16} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                    185.6} ${doorLeftWidth1 + 72.77} ${doorTopHeight1 +
                    185.6} ${doorLeftWidth1 + 77.82} ${doorTopHeight1 + 181.16}`
                "
              />
              <polygon
                class="Solid"
                :points="
                  `${doorLeftWidth1 + 77.64} ${doorTopHeight1 +
                    40.91} ${doorLeftWidth1 + 77.82} ${doorTopHeight1 +
                    181.16} ${doorLeftWidth1 + 72.77} ${doorTopHeight1 +
                    185.6} ${doorLeftWidth1 + 72.77} ${doorTopHeight1 +
                    36.48} ${doorLeftWidth1 + 77.64} ${doorTopHeight1 + 40.91}`
                "
              />
              <polygon
                class="Solid"
                :points="
                  `${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                    181.16} ${doorLeftWidth1 + 104.72} ${doorTopHeight1 +
                    40.91} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                    36.48} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                    185.6} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                    181.16}`
                "
              />
              <polygon
                class="Molding-T"
                :points="
                  `${doorLeftWidth1 + 104.72} ${doorTopHeight1 +
                    40.91} ${doorLeftWidth1 + 77.64} ${doorTopHeight1 +
                    40.91} ${doorLeftWidth1 + 72.77} ${doorTopHeight1 +
                    36.48} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                    36.48} ${doorLeftWidth1 + 104.72} ${doorTopHeight1 + 40.91}`
                "
              />
              <polygon
                class="Molding-B"
                :points="
                  `${doorLeftWidth1 + 77.82} ${doorTopHeight1 +
                    181.16} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                    181.16} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                    185.6} ${doorLeftWidth1 + 72.77} ${doorTopHeight1 +
                    185.6} ${doorLeftWidth1 + 77.82} ${doorTopHeight1 + 181.16}`
                "
              />
              <polygon
                class="Molding-L"
                :points="
                  `${doorLeftWidth1 + 77.64} ${doorTopHeight1 +
                    40.91} ${doorLeftWidth1 + 77.82} ${doorTopHeight1 +
                    181.16} ${doorLeftWidth1 + 72.77} ${doorTopHeight1 +
                    185.6} ${doorLeftWidth1 + 72.77} ${doorTopHeight1 +
                    36.48} ${doorLeftWidth1 + 77.64} ${doorTopHeight1 + 40.91}`
                "
              />
              <polygon
                class="Molding-R"
                :points="
                  `${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                    181.16} ${doorLeftWidth1 + 104.72} ${doorTopHeight1 +
                    40.91} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                    36.48} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                    185.6} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                    181.16}`
                "
              />
            </g>
            <g id="Molding_B" data-name="Molding B">
              <polygon
                class="Solid"
                :points="
                  `${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                    198.32} ${doorLeftWidth1 + 34.17} ${doorTopHeight1 +
                    198.32} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                    193.88} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                    193.88} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                    198.32}`
                "
              />
              <polygon
                class="Solid"
                :points="
                  `${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                    255.72} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                    255.72} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                    260.15} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                    260.15} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                    255.72}`
                "
              />
              <polygon
                class="Solid"
                :points="
                  `${doorLeftWidth1 + 34.17} ${doorTopHeight1 +
                    198.32} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                    255.72} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                    260.15} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                    193.88} ${doorLeftWidth1 + 34.17} ${doorTopHeight1 +
                    198.32}`
                "
              />
              <polygon
                class="Solid"
                :points="
                  `${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                    255.72} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                    198.32} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                    193.88} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                    260.15} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                    255.72}`
                "
              />
              <polygon
                class="Molding-T"
                :points="
                  `${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                    198.32} ${doorLeftWidth1 + 34.17} ${doorTopHeight1 +
                    198.32} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                    193.88} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                    193.88} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                    198.32}`
                "
              />
              <polygon
                class="Molding-B"
                :points="
                  `${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                    255.72} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                    255.72} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                    260.15} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                    260.15} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                    255.72}`
                "
              />
              <polygon
                class="Molding-L"
                :points="
                  `${doorLeftWidth1 + 34.17} ${doorTopHeight1 +
                    198.32} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                    255.72} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                    260.15} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                    193.88} ${doorLeftWidth1 + 34.17} ${doorTopHeight1 +
                    198.32}`
                "
              />
              <polygon
                class="Molding-R"
                :points="
                  `${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                    255.72} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                    198.32} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                    193.88} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                    260.15} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                    255.72}`
                "
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showMolding'
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth / 2 - 70;
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight / 2 - 148;
    }
  }
};
</script>
