<template>
  <g>
    <svg:style>
      .cls-1 {
        isolation: isolate;
      }

      .cls-2,
      .cls-3,
      .cls-22 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }
      .cls-2 {
        fill: url(#BG-gradient);
      }
      .cls-3 {
        fill: none;
      }
      .cls-4 {
        opacity: 0.5;
        mix-blend-mode: multiply;
      }
      .cls-5 {
        fill: url(#radial-gradient);
      }
      .cls-6 {
        fill: url(#Bottom_Gradient_2);
      }
      .cls-22 {
        fill: url(#glass-pattern);
      }
      .cls-7 {
        fill: url(#LR_Gradient_2);
      }
      .cls-8 {
        fill: url(#LR_Gradient_2-2);
      }
      .cls-9 {
        fill: url(#Top_Gradient_2);
      }
      .cls-10 {
        fill: url(#Bottom_Gradient_2-2);
      }
      .cls-11 {
        fill: url(#LR_Gradient_2-3);
      }
      .cls-12 {
        fill: url(#LR_Gradient_2-4);
      }
      .cls-13 {
        fill: url(#Top_Gradient_2-2);
      }
      .cls-14 {
        fill: url(#New_Gradient_Swatch_3);
      }
      .cls-15 {
        fill: url(#New_Gradient_Swatch_3-2);
      }
      .cls-16 {
        fill: url(#New_Gradient_Swatch_3-3);
      }
      .cls-17 {
        fill: url(#New_Gradient_Swatch_3-4);
      }
      .cls-18 {
        fill: url(#New_Gradient_Swatch_3-5);
      }
      .cls-19 {
        fill: url(#New_Gradient_Swatch_3-6);
      }
      .cls-20 {
        fill: url(#New_Gradient_Swatch_3-7);
      }
      .cls-21 {
        fill: url(#New_Gradient_Swatch_3-8);
      }
      .Solid { fill: {{ doorSolidColor }} }
      .Molding-L { fill: url(#Molding-L); } /* left */
      .Molding-T { fill: url(#Molding-T); } /* top */
      .Molding-R { fill: url(#Molding-R); } /* right */
      .Molding-B { fill: url(#Molding-B); } /* bottom */
    </svg:style>
    <linearGradient
      id="glass-gradient"
      :x1="doorLeftWidth1 + 69.66"
      :y1="doorTopHeight1 + 181.16"
      :x2="doorLeftWidth1 + 69.66"
      :y2="doorTopHeight1 + 40.87"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#f4f4f4" />
      <stop offset="0.5" stop-color="#dee8e8" />
      <stop offset="1" stop-color="#d1cece" />
    </linearGradient>
    <linearGradient
      id="BG-gradient"
      :x1="doorLeftWidth1 + 70.12"
      :y1="doorTopHeight1 + 0.12"
      :x2="doorLeftWidth1 + 70.13"
      :y2="doorTopHeight1 + 294.12"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.25" stop-color="#f9f9f9" />
      <stop offset="0.67" stop-color="#e8e8e8" />
      <stop offset="0.79" stop-color="#e2e2e2" />
    </linearGradient>
    <radialGradient
      id="radial-gradient"
      :cx="doorLeftWidth1 + 14"
      :cy="doorTopHeight1 + 146.78"
      r="3.7"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#977c00" />
      <stop offset="0.5" stop-color="#956f00" />
      <stop offset="0.89" stop-color="#8d5e00" />
      <stop offset="1" stop-color="#cc8d00" />
    </radialGradient>
    <linearGradient
      id="Bottom_Gradient_2"
      data-name="Bottom Gradient 2"
      :x1="doorLeftWidth1 - 707.31"
      :y1="doorTopHeight1 + 7734.67"
      :x2="doorLeftWidth1 - 707.31"
      :y2="doorTopHeight1 + 7726.17"
      gradientTransform="translate(-637.69 7981.89) rotate(180)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#fff" stop-opacity="0.05" />
      <stop offset="0.05" stop-opacity="0.5" />
      <stop offset="0.06" stop-opacity="0.5" />
      <stop offset="0.58" stop-opacity="0.3" />
      <stop offset="0.87" stop-opacity="0.3" />
      <stop offset="0.89" stop-opacity="0.3" />
      <stop offset="1" stop-opacity="0.6" />
    </linearGradient>
    <linearGradient
      id="LR_Gradient_2"
      data-name="LR Gradient 2"
      :x1="doorLeftWidth1 - 128.91"
      :y1="doorTopHeight1 + 9387.2"
      :x2="doorLeftWidth1 - 128.91"
      :y2="doorTopHeight1 + 9378.67"
      gradientTransform="translate(-9344.5 98.11) rotate(-90)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#fff" stop-opacity="0.3" />
      <stop offset="0.01" stop-color="#e9e9e9" stop-opacity="0.3" />
      <stop offset="0.02" stop-color="#ababab" stop-opacity="0.3" />
      <stop offset="0.03" stop-color="#454545" stop-opacity="0.3" />
      <stop offset="0.04" stop-opacity="0.3" />
      <stop offset="0.58" stop-opacity="0.1" />
      <stop offset="0.87" stop-opacity="0.28" />
      <stop offset="0.89" stop-opacity="0.3" />
      <stop offset="1" stop-opacity="0.6" />
    </linearGradient>
    <linearGradient
      id="LR_Gradient_2-2"
      :x1="doorLeftWidth1 + 96.5"
      :y1="doorTopHeight1 + 227.02"
      :x2="doorLeftWidth1 + 104.89"
      :y2="doorTopHeight1 + 227.02"
      gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
      xlink:href="#LR_Gradient_2"
    />
    <linearGradient
      id="Top_Gradient_2"
      data-name="Top Gradient 2"
      :x1="doorLeftWidth1 + 69.53"
      :y1="doorTopHeight1 + 207.14"
      :x2="doorLeftWidth1 + 69.53"
      :y2="doorTopHeight1 + 198.32"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#fff" stop-opacity="0.6" />
      <stop offset="0" stop-color="#fff" stop-opacity="0.56" />
      <stop offset="0" stop-color="#fff" stop-opacity="0.44" />
      <stop offset="0.01" stop-color="#fff" stop-opacity="0.33" />
      <stop offset="0.01" stop-color="#fff" stop-opacity="0.25" />
      <stop offset="0.02" stop-color="#fff" stop-opacity="0.18" />
      <stop offset="0.03" stop-color="#fff" stop-opacity="0.13" />
      <stop offset="0.04" stop-color="#fff" stop-opacity="0.11" />
      <stop offset="0.06" stop-color="#fff" stop-opacity="0.1" />
      <stop offset="0.26" stop-color="#fff" stop-opacity="0.04" />
      <stop offset="0.47" stop-color="#fff" stop-opacity="0" />
      <stop offset="0.87" stop-opacity="0.2" />
      <stop offset="1" stop-opacity="0.5" />
    </linearGradient>
    <linearGradient
      id="Bottom_Gradient_2-2"
      :x1="doorLeftWidth1 - 707.31"
      :y1="doorTopHeight1 + 7809.22"
      :x2="doorLeftWidth1 - 707.31"
      :y2="doorTopHeight1 + 7800.73"
      xlink:href="#Bottom_Gradient_2"
    />
    <linearGradient
      id="LR_Gradient_2-3"
      :x1="doorLeftWidth1 - 12.93"
      :y1="doorTopHeight1 + 9387.2"
      :x2="doorLeftWidth1 - 12.93"
      :y2="doorTopHeight1 + 9378.67"
      xlink:href="#LR_Gradient_2"
    />
    <linearGradient
      id="LR_Gradient_2-4"
      :x1="doorLeftWidth1 + 96.5"
      :y1="doorTopHeight1 + 111.04"
      :x2="doorLeftWidth1 + 104.89"
      :y2="doorTopHeight1 + 111.04"
      gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
      xlink:href="#LR_Gradient_2"
    />
    <linearGradient
      id="Top_Gradient_2-2"
      :x1="doorLeftWidth1 + 69.53"
      :y1="doorTopHeight1 + 49.74"
      :x2="doorLeftWidth1 + 69.53"
      :y2="doorTopHeight1 + 40.92"
      xlink:href="#Top_Gradient_2"
    />
    <linearGradient
      id="Molding-L"
      data-name="Molding"
      x1="0"
      y1="0"
      x2="100%"
      y2="0"
    >
      <stop offset="0" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4" />
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1" />
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4" />
      <stop offset="1" stop-color="#000" stop-opacity="0.5" />
    </linearGradient>
    <linearGradient
      id="Molding-T"
      data-name="Molding"
      x1="0"
      y1="0"
      x2="0"
      y2="100%"
    >
      <stop offset="0" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4" />
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1" />
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4" />
      <stop offset="1" stop-color="#000" stop-opacity="0.5" />
    </linearGradient>
    <linearGradient
      id="Molding-R"
      data-name="Molding"
      x1="100%"
      y1="0"
      x2="0"
      y2="0"
    >
      <stop offset="0" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4" />
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1" />
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4" />
      <stop offset="1" stop-color="#000" stop-opacity="0.5" />
    </linearGradient>
    <linearGradient
      id="Molding-B"
      data-name="Molding"
      x1="0"
      y1="100%"
      x2="0"
      y2="0"
    >
      <stop offset="0" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4" />
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1" />
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4" />
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4" />
      <stop offset="1" stop-color="#000" stop-opacity="0.5" />
    </linearGradient>
    <g class="cls-1">
      <g id="Models_summer_2023" data-name="Models summer 2023">
        <rect
          id="glass"
          class="cls-22"
          :x="`${doorLeftWidth1 + 34.47}`"
          :y="`${doorTopHeight1 + 40.87}`"
          :width="`${70.38}`"
          :height="`${140.29}`"
        />
        <g id="Panel_BR" data-name="Panel BR">
          <polygon
            id="Shadow"
            class="cls-6"
            :points="
              `${doorLeftWidth1 + 42.7} ${doorTopHeight1 +
                247.22} ${doorLeftWidth1 + 96.5} ${doorTopHeight1 +
                247.22} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                255.72} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                255.72} ${doorLeftWidth1 + 42.7} ${doorTopHeight1 + 247.22}`
            "
          />
          <polygon
            id="Panel_L"
            data-name="Panel L"
            class="cls-7"
            :points="
              `${doorLeftWidth1 + 42.7} ${doorTopHeight1 +
                207.15} ${doorLeftWidth1 + 42.7} ${doorTopHeight1 +
                247.22} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                255.72} ${doorLeftWidth1 + 34.17} ${doorTopHeight1 +
                198.32} ${doorLeftWidth1 + 42.7} ${doorTopHeight1 + 207.15}`
            "
          />
          <polygon
            id="Panel_R"
            data-name="Panel R"
            class="cls-8"
            :points="
              `${doorLeftWidth1 + 96.5} ${doorTopHeight1 +
                206.87} ${doorLeftWidth1 + 96.5} ${doorTopHeight1 +
                247.22} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                255.72} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                198.32} ${doorLeftWidth1 + 96.5} ${doorTopHeight1 + 206.87}`
            "
          />
          <polygon
            id="Highlight"
            class="cls-9"
            :points="
              `${doorLeftWidth1 + 96.5} ${doorTopHeight1 +
                206.86} ${doorLeftWidth1 + 42.7} ${doorTopHeight1 +
                207.15} ${doorLeftWidth1 + 34.17} ${doorTopHeight1 +
                198.32} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                198.32} ${doorLeftWidth1 + 96.5} ${doorTopHeight1 + 206.86}`
            "
          />
        </g>
        <g id="Panel_BR-2" data-name="Panel BR" v-if="!doorGlassImage">
          <polygon
            id="Shadow-2"
            data-name="Shadow"
            class="cls-10"
            :points="
              `${doorLeftWidth1 + 42.7} ${doorTopHeight1 +
                172.66} ${doorLeftWidth1 + 96.5} ${doorTopHeight1 +
                172.66} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                181.16} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                181.16} ${doorLeftWidth1 + 42.7} ${doorTopHeight1 + 172.66}`
            "
          />
          <polygon
            id="Panel_L-2"
            data-name="Panel L"
            class="cls-11"
            :points="
              `${doorLeftWidth1 + 42.7} ${doorTopHeight1 +
                49.74} ${doorLeftWidth1 + 42.7} ${doorTopHeight1 +
                172.66} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                181.16} ${doorLeftWidth1 + 34.17} ${doorTopHeight1 +
                40.91} ${doorLeftWidth1 + 42.7} ${doorTopHeight1 + 49.74}`
            "
          />
          <polygon
            id="Panel_R-2"
            data-name="Panel R"
            class="cls-12"
            :points="
              `${doorLeftWidth1 + 96.5} ${doorTopHeight1 +
                49.47} ${doorLeftWidth1 + 96.5} ${doorTopHeight1 +
                172.66} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                181.16} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                40.91} ${doorLeftWidth1 + 96.5} ${doorTopHeight1 + 49.47}`
            "
          />
          <polygon
            id="Highlight-2"
            data-name="Highlight"
            class="cls-13"
            :points="
              `${doorLeftWidth1 + 96.5} ${doorTopHeight1 +
                49.46} ${doorLeftWidth1 + 42.7} ${doorTopHeight1 +
                49.74} ${doorLeftWidth1 + 34.17} ${doorTopHeight1 +
                40.91} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                40.91} ${doorLeftWidth1 + 96.5} ${doorTopHeight1 + 49.46}`
            "
          />
        </g>
        <g id="Moldings" v-if="showMolding">
          <g id="Molding">
            <polygon
              class="Solid"
              :points="
                `${doorLeftWidth1 + 104.85} ${doorTopHeight1 +
                  40.91} ${doorLeftWidth1 + 34.18} ${doorTopHeight1 +
                  40.91} ${doorLeftWidth1 + 29.4} ${doorTopHeight1 +
                  36.48} ${doorLeftWidth1 + 109.81} ${doorTopHeight1 +
                  36.48} ${doorLeftWidth1 + 104.85} ${doorTopHeight1 + 40.91}`
              "
            />
            <polygon
              class="Solid"
              :points="
                `${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                  181.16} ${doorLeftWidth1 + 104.98} ${doorTopHeight1 +
                  181.16} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                  185.6} ${doorLeftWidth1 + 29.4} ${doorTopHeight1 +
                  185.6} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 + 181.16}`
              "
            />
            <polygon
              class="Solid"
              :points="
                `${doorLeftWidth1 + 34.18} ${doorTopHeight1 +
                  40.91} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                  181.16} ${doorLeftWidth1 + 29.4} ${doorTopHeight1 +
                  185.6} ${doorLeftWidth1 + 29.4} ${doorTopHeight1 +
                  36.48} ${doorLeftWidth1 + 34.18} ${doorTopHeight1 + 40.91}`
              "
            />
            <polygon
              class="Solid"
              :points="
                `${doorLeftWidth1 + 105.03} ${doorTopHeight1 +
                  181.16} ${doorLeftWidth1 + 104.85} ${doorTopHeight1 +
                  40.91} ${doorLeftWidth1 + 109.81} ${doorTopHeight1 +
                  36.48} ${doorLeftWidth1 + 109.81} ${doorTopHeight1 +
                  185.6} ${doorLeftWidth1 + 105.03} ${doorTopHeight1 + 181.16}`
              "
            />
            <polygon
              class="Molding-T"
              :points="
                `${doorLeftWidth1 + 104.85} ${doorTopHeight1 +
                  40.91} ${doorLeftWidth1 + 34.18} ${doorTopHeight1 +
                  40.91} ${doorLeftWidth1 + 29.4} ${doorTopHeight1 +
                  36.48} ${doorLeftWidth1 + 109.81} ${doorTopHeight1 +
                  36.48} ${doorLeftWidth1 + 104.85} ${doorTopHeight1 + 40.91}`
              "
            />
            <polygon
              class="Molding-B"
              :points="
                `${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                  181.16} ${doorLeftWidth1 + 104.98} ${doorTopHeight1 +
                  181.16} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                  185.6} ${doorLeftWidth1 + 29.4} ${doorTopHeight1 +
                  185.6} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 + 181.16}`
              "
            />
            <polygon
              class="Molding-L"
              :points="
                `${doorLeftWidth1 + 34.18} ${doorTopHeight1 +
                  40.91} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                  181.16} ${doorLeftWidth1 + 29.4} ${doorTopHeight1 +
                  185.6} ${doorLeftWidth1 + 29.4} ${doorTopHeight1 +
                  36.48} ${doorLeftWidth1 + 34.18} ${doorTopHeight1 + 40.91}`
              "
            />
            <polygon
              class="Molding-R"
              :points="
                `${doorLeftWidth1 + 105.03} ${doorTopHeight1 +
                  181.16} ${doorLeftWidth1 + 104.85} ${doorTopHeight1 +
                  40.91} ${doorLeftWidth1 + 109.81} ${doorTopHeight1 +
                  36.48} ${doorLeftWidth1 + 109.81} ${doorTopHeight1 +
                  185.6} ${doorLeftWidth1 + 105.03} ${doorTopHeight1 + 181.16}`
              "
            />
          </g>
          <g id="Molding_B" data-name="Molding B">
            <polygon
              class="Solid"
              :points="
                `${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                  198.32} ${doorLeftWidth1 + 34.17} ${doorTopHeight1 +
                  198.32} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                  193.88} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                  193.88} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 + 198.32}`
              "
            />
            <polygon
              class="Solid"
              :points="
                `${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                  255.72} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                  255.72} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                  260.15} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                  260.15} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 + 255.72}`
              "
            />
            <polygon
              class="Solid"
              :points="
                `${doorLeftWidth1 + 34.17} ${doorTopHeight1 +
                  198.32} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                  255.72} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                  260.15} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                  193.88} ${doorLeftWidth1 + 34.17} ${doorTopHeight1 + 198.32}`
              "
            />
            <polygon
              class="Solid"
              :points="
                `${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                  255.72} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                  198.32} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                  193.88} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                  260.15} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 + 255.72}`
              "
            />
            <polygon
              class="Molding-T"
              :points="
                `${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                  198.32} ${doorLeftWidth1 + 34.17} ${doorTopHeight1 +
                  198.32} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                  193.88} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                  193.88} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 + 198.32}`
              "
            />
            <polygon
              class="Molding-B"
              :points="
                `${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                  255.72} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                  255.72} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                  260.15} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                  260.15} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 + 255.72}`
              "
            />
            <polygon
              class="Molding-L"
              :points="
                `${doorLeftWidth1 + 34.17} ${doorTopHeight1 +
                  198.32} ${doorLeftWidth1 + 34.35} ${doorTopHeight1 +
                  255.72} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                  260.15} ${doorLeftWidth1 + 29.3} ${doorTopHeight1 +
                  193.88} ${doorLeftWidth1 + 34.17} ${doorTopHeight1 + 198.32}`
              "
            />
            <polygon
              class="Molding-R"
              :points="
                `${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                  255.72} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 +
                  198.32} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                  193.88} ${doorLeftWidth1 + 109.77} ${doorTopHeight1 +
                  260.15} ${doorLeftWidth1 + 104.89} ${doorTopHeight1 + 255.72}`
              "
            />
          </g>
        </g>
      </g>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showMolding',
    'doorSolidColor'
  ],
  computed: {
    doorLeftWidth1() {
      const result = this.doorLeftWidth + this.doorWidth / 2 - 70;
      return result;
    },
    doorTopHeight1() {
      const result = this.doorTopHeight + this.doorHeight / 2 - 148;
      return result;
    }
  }
};
</script>
