<template>
  <g>
    <handle-gradient
      :doorLeftWidth="doorLeftWidth"
      :handleX="handleX"
      :handleY="handleY"
    />
    <image
      :href="FurnitureSvgSource"
      :width="FurnitureSvgWidth"
      :height="FurnitureSvgHeight"
      :x="FurnitureSvgX"
      :y="FurnitureSvgY"
    />
  </g>
</template>

<script>
import { mapGetters } from 'vuex';
import HandleGradient from '@/components/door/model-handle-gradient.vue';

export default {
  components: {
    HandleGradient
  },
  computed: {
    ...mapGetters('config', ['furnitureSub', 'shape']),
    FurnitureSvgSource() {
      return this.furnitureSub.image_svg;
    },
    FurnitureSvgWidth() {
      return this.furnitureSub.width;
    },
    FurnitureSvgHeight() {
      return this.furnitureSub.height;
    },
    FurnitureSvgX() {
      return this.handleX;
    },
    FurnitureSvgY() {
      if (this.doorTopHeight) {
        return Number(this.furnitureSub.positionY) + Number(this.doorTopHeight);
      }
      return this.furnitureSub.positionY;
    }
  },
  props: {
    doorTopHeight: {
      type: Number,
      default: 0
    },
    doorLeftWidth: {
      type: Number,
      default: 0
    },
    handleX: {
      type: Number,
      default: 0
    },
    handleY: {
      type: Number,
      default: 0
    },
    isBlack: {
      type: Boolean,
      default: false
    },
    handleWidth: {
      type: Number,
      default: 0
    },
    handleHeight: {
      type: Number,
      default: 0
    }
  }
};
</script>
