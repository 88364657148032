<template>
  <div class="overlay">
    <page-title>{{ i18n.MENU.furniture }}</page-title>
    <div class="page-width" v-if="model === ''">
      <div class="furniture__empty">
        {{ i18n.FURNITURE.SELECT_MODEL }}
      </div>
    </div>
    <div class="page-width" v-if="model">
      <div class="furniture">
        <div class="furniture__empty" v-if="items === null">
          {{ i18n.FURNITURE.EMPTY_FURNITURE }}
        </div>
        <div
          :class="[
            'accordion__item',
            { 'accordion__item--opened': showFurniture }
          ]"
        >
          <button class="accordion__heading" @click.prevent="toggleFurniture">
            <div class="accordion__text">{{ items.title }}</div>
          </button>
          <div
            :class="[
              'accordion__content',
              { 'accordion__content--active': showFurniture }
            ]"
          >
            <div class="page-width">
              <div>
                <div class="options ">
                  <div
                    class="accordion__empty"
                    v-if="furnitureItems.length === 0"
                  >
                    {{ i18n.OPTIONS.EMPTY_CONSTRUCTION }}
                  </div>
                  <div class="categories">
                    <div
                      class="categories__main"
                      v-for="(item, index) in furnitureItems"
                      :key="`options__${item.ord}`"
                    >
                      <label
                        :class="[
                          'options__item',
                          'categories__item',
                          {
                            'options__item--current':
                              item.id ===
                              (house === 'inside'
                                ? furnitureInside.id
                                : furniture.id)
                          },
                          {
                            'options__item--active': item.active === '1'
                          }
                        ]"
                      >
                        <div class="categories__present">
                          <div>
                            <span class="options__bg">
                              <img class="options__img" :src="item.image_png" />
                            </span>
                            <div class="options__title">{{ item.title }}</div>
                          </div>
                        </div>
                        <div class="radio">
                          <div
                            class="option"
                            v-for="(subitem, index) in item.subitems"
                            :key="`option_${subitem.id}`"
                          >
                            <p :class="`option__${index}`">
                              <input
                                type="radio"
                                :id="`furniture_${subitem.id}`"
                                :value="subitem.id"
                                :checked="subitem.id === currentId"
                                :disabled="house === 'inside'"
                                @change.prevent="
                                  setFurniture(
                                    item,
                                    subitem,
                                    subitem.id === currentId
                                  )
                                "
                                name="furniture"
                              />
                              <label :for="`furniture_${subitem.id}`">{{
                                subitem.title
                              }}</label>
                            </p>
                          </div>
                        </div>
                      </label>
                      <hr
                        :class="
                          `categories__hr hr__${
                            furnitureItems.length - 1 === index ? 'last' : index
                          }`
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/page-title/page-title.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import { API } from '@/constants/api';
import { setCode } from '@/utils/code';

export default {
  data() {
    return {
      projection: 'outside',
      items: [],
      showFurniture: false
    };
  },
  components: {
    PageTitle
  },
  computed: {
    ...mapGetters('session', ['i18n']),
    ...mapGetters('config', [
      'shape',
      'model',
      'glass',
      'color',
      'furniture',
      'options',
      'inox',
      'view',
      'mirrored',
      'black',
      'furnitureInside',
      'house',
      'furnitureSub',
      'furnitureInsideSubId'
    ]),
    furnitureItems() {
      if (this.items && this.items.items) {
        if (this.color.outside.category === '2') {
          return this.items.items
            .filter(item => item.id !== '20')
            .filter(item => item.id !== '21');
        }
        return this.items.items;
      }
      return [];
    },
    currentId() {
      return this.house === 'inside'
        ? this.furnitureInsideSubId.id
        : this.furnitureSub.id;
    }
  },
  metaInfo() {
    return {
      title: this.i18n.MENU.furniture,
      meta: [
        { vmid: 'description', property: 'description', content: 'Фурнітура' },
        { vmid: 'og:title', property: 'og:title', content: 'Фурнітура' },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: 'Фурнітура'
        }
      ]
    };
  },
  methods: {
    toggleFurniture() {
      this.showFurniture = !this.showFurniture;
    },
    setProjection(value) {
      this.projection = value;
    },
    getFurniture() {
      axios
        .get(API.GET_FURNITURE, { params: { model: this.model.id } })
        .then(response => (this.items = response.data[0]));
    },
    async setFurniture(item, subitem, isActive) {
      if (isActive) {
        return false;
      }
      let response;
      const {
        shape,
        model,
        glass,
        color,
        // furniture,
        options,
        inox,
        view,
        mirrored,
        black
      } = this;
      const moldingView = inox?.url.includes('molding')
        ? inox?.url.split('-')[1]
        : null;
      try {
        response = await axios.get(API.GET_CODE_FROM_CONFIG, {
          params: {
            shape: shape.id,
            model: model.id,
            glass: glass.id,
            colour_outside: color.outside.id,
            colour_inside: color.inside.id,
            furniture: subitem.id,
            options: options.id,
            inox: inox.id,
            molding: moldingView,
            view: view.id,
            mirrored: mirrored.id,
            black
          }
        });
      } catch (e) {
        console.error(e);
      }
      if (this.house === 'outside') {
        await setCode({
          code: response.data.code,
          store: this.$store,
          router: this.$router
        });
        await this.$store.dispatch('config/CONFIG_SET_FURNITURE', item);
        await this.$store.dispatch('config/CONFIG_SET_FURNITURE_SUB', subitem);
      }
    }
  },
  created() {
    this.getFurniture();
  }
};
</script>

<style lang="scss">
.accordion {
  margin: 20px 30px 0;

  &__empty {
    color: #ffffff;
  }

  &__item {
    border: none;
    margin-top: 10px;

    &--active {
      border: 1px solid #c70552;
    }
  }

  &__text {
    padding-left: 16px;
  }

  &__heading {
    width: 100%;
    height: 40px;
    background: #272c30;
    border-radius: 3px;
    text-align: left;
    font: 15px/18px 'ProximaNovaRegular', sans-serif;
    color: #ffffff;
    border: none;
    cursor: pointer;
    position: relative;
    &--active {
      background: #c70552;
    }
    &:before {
      content: '';
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(0, -50%);
      width: 10px;
      height: 16px;
      background: url(~@/assets/images/angle-up.svg) no-repeat 0 0/10px 6px,
        url(~@/assets/images/angle-down.svg) no-repeat 0 100%/10px 6px;
      .accordion__item--opened & {
        background: url(~@/assets/images/angle-up.svg) no-repeat 0 100%/10px 6px,
          url(~@/assets/images/angle-down.svg) no-repeat 0 0/10px 6px;
      }
    }
  }

  &__content {
    margin: 0;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s linear;
    &--active {
      max-height: 1450px;
    }
  }
}

.page-width {
  padding: 30px;
}

.options {
  margin: 10px -10px 0;
  display: flex;
  flex-wrap: wrap;

  &__item {
    flex: 1 0 50%;
    max-width: 50%;
    padding: 10px;
    opacity: 0.5;
    &--active {
      opacity: 1;
      cursor: pointer;
    }
    &--current {
      cursor: default;
    }
    &--active:hover,
    &--current {
      .options__bg {
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          border: 8px solid #c70552;
          z-index: 2;
        }
      }
    }
  }
  &__bg {
    display: block;
    position: relative;
    height: 0;
    padding-bottom: 100%;
  }

  &__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  &__inp {
    visibility: hidden;
  }

  &__title {
    text-align: center;
    font: 15px/18px 'ProximaNovaRegular', sans-serif;
    color: #ffffff;
    margin-top: 10px;
  }
}
.radio {
  margin: 0px 20px;
  .option {
    &__0 {
      margin-top: 0;
    }
  }
}
.categories {
  flex: 1 1 100%;
  flex-direction: column;
  @include maw($xl) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &__main {
    flex: 0 0 50%;
    @include maw($xl) {
      display: flex;
      flex-direction: column;
    }
  }
  &__item {
    display: flex;
    flex-direction: row;
    @include maw($xl) {
      max-width: 100%;
    }
    @include maw($md) {
      max-width: 100%;
    }
  }
  &__present {
    flex: 1 0 100%;
    @include maw($xl) {
      flex: 1 0 50%;
      max-width: 50%;
    }
  }
  @include maw($lg) {
    display: block;
  }

  &__hr {
    margin: 20px 0;
    border: 1px solid #aaa;
  }
  .hr__last {
    display: none;
  }

  .furniture {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #5c646b;
    &__empty {
      color: #ffffff;
    }
    &__item {
      flex: 1 0 50%;
      max-width: 50%;
      padding: 10px;
      opacity: 0.5;
      &--active {
        cursor: pointer;
        opacity: 1;
      }
      &--active:hover,
      &--current {
        .furniture__bg {
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            border: 8px solid #c70552;
            z-index: 2;
          }
        }
      }
    }
    &__bg {
      display: block;
      position: relative;
      height: 0;
      padding-bottom: 100%;
    }
    &__img {
      left: 0;
      top: 0;
      width: 100%;
      position: relative;
    }
    &__title {
      text-align: center;
      font: 15px/18px 'ProximaNovaRegular', sans-serif;
      color: #ffffff;
      margin-top: 16px;
    }
  }
}
</style>
<style lang="scss" scoped>
.option {
  display: flex;
  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type='radio']:checked + label,
  [type='radio']:not(:checked) + label {
    white-space: nowrap;
    word-wrap: break-word;
    max-width: 200px;
    position: relative;
    padding: 4px 20px 0 40px;
    cursor: pointer;
    font: 15px/18px 'ProximaNovaRegular', sans-serif;
    color: #ffffff;
    display: flex;
  }
  [type='radio']:checked + label:before,
  [type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border: 1px solid #7d8b97;
    border-radius: 100px;
  }
  [type='radio']:checked + label:after,
  [type='radio']:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #c70552;
    position: absolute;
    top: 8px;
    left: 8px;
    border-radius: 100px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
</style>
