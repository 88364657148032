var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overlay"},[_c('page-title',[_vm._v(_vm._s(_vm.i18n.MENU.options))]),_c('div',{staticClass:"accordion"},[_c('div',{class:[
        'accordion__item',
        { 'accordion__item--opened': _vm.showConstruction }
      ]},[_c('button',{staticClass:"accordion__heading",on:{"click":function($event){$event.preventDefault();return _vm.toggleConstruction($event)}}},[_c('div',{staticClass:"accordion__text"},[_vm._v(_vm._s(_vm.i18n.OPTIONS.CONSTRUCTION))])]),_c('div',{class:[
          'accordion__content',
          { 'accordion__content--active': _vm.showConstruction }
        ]},[_c('div',{staticClass:"page-width"},[_c('div',[_c('div',{staticClass:"options "},[(_vm.construction.length === 0)?_c('div',{staticClass:"accordion__empty"},[_vm._v(" "+_vm._s(_vm.i18n.OPTIONS.EMPTY_CONSTRUCTION)+" ")]):_vm._e(),_c('div',{staticClass:"categories"},_vm._l((_vm.construction),function(item,index){return _c('div',{key:("options__" + (item.ord)),staticClass:"categories__main"},[_c('label',{class:[
                      'options__item',
                      'categories__item',
                      {
                        'options__item--current': item.construction_thikness.some(
                          function (construction) { return construction.id === _vm.currentId; }
                        )
                      },
                      {
                        'options__item--active': item
                      }
                    ]},[_c('div',{staticClass:"categories__present"},[_c('div',[_c('span',{staticClass:"options__bg"},[_c('img',{staticClass:"options__img",attrs:{"src":item.image_png}})]),_c('div',{staticClass:"options__title"},[_vm._v(_vm._s(item.title))])])]),_c('div',{staticClass:"radio"},_vm._l((item.construction_thikness),function(size,index){return _c('div',{key:("option_" + (size.id)),staticClass:"option"},[_c('p',{class:("option__" + index)},[_c('input',{attrs:{"type":"radio","id":("size_" + (size.id)),"name":"size"},domProps:{"value":size.id,"checked":size.id === _vm.currentId},on:{"change":function($event){$event.preventDefault();return _vm.setConstruction(size, _vm.options)}}}),_c('label',{attrs:{"for":("size_" + (size.id))}},[_vm._v(_vm._s(size.construction_thikness + ' мм'))])])])}),0)]),_c('hr',{class:("categories__hr hr__" + (_vm.construction.length - 1 === index ? 'last' : index))})])}),0)])])])])]),_c('div',{class:['accordion__item', { 'accordion__item--opened': _vm.showInoxes }]},[_c('button',{staticClass:"accordion__heading",on:{"click":function($event){$event.preventDefault();return _vm.toggleInoxes($event)}}},[_c('div',{staticClass:"accordion__text"},[_vm._v(_vm._s(_vm.i18n.OPTIONS.INOX))])]),_c('div',{class:[
          'accordion__content',
          { 'accordion__content--active': _vm.showInoxes }
        ]},[_c('div',{staticClass:"page-width"},[_c('div',[_c('tabset',{attrs:{"tabs":[].concat( _vm.i18n.INOX_TABS ),"active":_vm.activeTabInox},on:{"switchTab":_vm.toggleTabInox}}),_c('div',{staticClass:"options"},[(_vm.filteredInoxes.length === 0)?_c('div',{staticClass:"accordion__empty"},[_vm._v(" "+_vm._s(_vm.i18n.OPTIONS.EMPTY_CONSTRUCTION)+" ")]):_vm._e(),_vm._l((_vm.filteredInoxes),function(item){return _c('label',{key:("inox_" + (item.id)),class:[
                  'options__item',
                  { 'options__item--current': item.id === _vm.currentInox },
                  { 'options__item--active': item.active === '1' }
                ],on:{"click":function($event){$event.preventDefault();return _vm.setInox(item, item.id === _vm.inox.id)}}},[_c('span',{staticClass:"options__bg"},[_c('img',{staticClass:"options__img",attrs:{"src":item.image_png}})]),_c('div',{staticClass:"options__title"},[_vm._v(_vm._s(item.title))])])})],2)],1)])])]),_c('div',{class:[
        'accordion__item',
        { 'accordion__item--opened': _vm.showDirection }
      ]},[_c('button',{staticClass:"accordion__heading",on:{"click":function($event){$event.preventDefault();return _vm.toggleDirection($event)}}},[_c('div',{staticClass:"accordion__text"},[_vm._v(_vm._s(_vm.i18n.OPTIONS.DIRECTION))])]),_c('div',{class:[
          'accordion__content',
          { 'accordion__content--active': _vm.showDirection }
        ]},[_c('div',{staticClass:"page-width"},[_c('div',[_c('div',{staticClass:"options"},[(_vm.views.length === 0)?_c('div',{staticClass:"accordion__empty"},[_vm._v(" "+_vm._s(_vm.i18n.OPTIONS.EMPTY_DIRECTION)+" ")]):_vm._e(),_vm._l((_vm.views),function(item){return _c('label',{key:("view__" + (item.id)),class:[
                  'options__item',
                  { 'options__item--current': item.id === _vm.currentViewId },
                  { 'options__item--active': item.active === '1' }
                ],on:{"click":function($event){$event.preventDefault();return _vm.setView(item, item.id === _vm.view.id)}}},[_c('span',{staticClass:"options__bg"},[_c('img',{staticClass:"options__img",attrs:{"src":item.image}})]),_c('div',{staticClass:"options__title"},[_vm._v(_vm._s(item.title))])])})],2)])])])]),(_vm.model.mirrored_code)?_c('div',{class:[
        'accordion__item',
        { 'accordion__item--opened': _vm.showMirrored }
      ]},[_c('button',{staticClass:"accordion__heading",on:{"click":function($event){$event.preventDefault();return _vm.toggleMirrored($event)}}},[_c('div',{staticClass:"accordion__text"},[_vm._v(_vm._s(_vm.i18n.OPTIONS.MODEL_VIEW))])]),_c('div',{class:[
          'accordion__content',
          { 'accordion__content--active': _vm.showMirrored }
        ]},[_c('div',{staticClass:"page-width"},_vm._l((_vm.mirroreds),function(item){return _c('div',{key:("mir_" + (item.id)),staticClass:"option"},[_c('p',[_c('input',{attrs:{"type":"radio","id":("mir_" + (item.id)),"name":"mirrored"},domProps:{"value":item.id,"checked":item.id === _vm.mirrored.id},on:{"change":function($event){return _vm.setMirrored(item, item.id === _vm.mirrored.id)}}}),_c('label',{attrs:{"for":("mir_" + (item.id))}},[_vm._v(_vm._s(item.title))])])])}),0)])]):_vm._e(),_c('div',{class:[
        'accordion__item',
        { 'accordion__item--opened': _vm.showSafeGlass }
      ]},[_c('button',{staticClass:"accordion__heading",on:{"click":function($event){$event.preventDefault();return _vm.toggleSafeGlass($event)}}},[_c('div',{staticClass:"accordion__text"},[_vm._v(_vm._s(_vm.i18n.OPTIONS.SAFE_GLASS))])]),_c('div',{class:[
          'accordion__content',
          { 'accordion__content--active': _vm.showSafeGlass }
        ]},[_c('div',{staticClass:"page-width"},_vm._l((_vm.safes),function(item){return _c('div',{key:("mir_" + (item.id)),staticClass:"option"},[_c('p',[_c('input',{attrs:{"type":"radio","id":("safeglass_" + (item.id)),"name":"safeglass"},domProps:{"value":item.id,"checked":item.id === _vm.safeglass.id},on:{"change":function($event){return _vm.setSafeGlass(item, item.id === _vm.safeglass.id)}}}),_c('label',{attrs:{"for":("safeglass_" + (item.id))}},[_vm._v(_vm._s(item.title))])])])}),0)])]),_c('div',{class:['accordion__item', { 'accordion__item--opened': _vm.showSizes }]},[_c('button',{staticClass:"accordion__heading",on:{"click":function($event){$event.preventDefault();return _vm.toggleSizes($event)}}},[_c('div',{staticClass:"accordion__text"},[_vm._v(_vm._s(_vm.i18n.OPTIONS.SIZES))])]),_c('div',{class:[
          'accordion__content',
          { 'accordion__content--active': _vm.showSizes }
        ]},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.optionsProcess)}}},[_c('div',{staticClass:"page-width"},[_c('div',{staticClass:"size"},[_c('div',{staticClass:"size__title"},[_vm._v(_vm._s(_vm.i18n.OPTIONS.SIZES_SELECT))]),_c('div',{staticClass:"size__text"},[_vm._v(" "+_vm._s(_vm.i18n.OPTIONS.W)+" "),_c('validation-provider',{attrs:{"rules":("required|number_between:" + _vm.minWidth + "," + _vm.maxWidth)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderWidth),expression:"orderWidth"}],staticClass:"size__field",attrs:{"type":"number","step":"1"},domProps:{"value":(_vm.orderWidth)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.orderWidth=$event.target.value},function($event){return _vm.check({
                          e: $event,
                          max: _vm.maxWidth,
                          min: _vm.minWidth,
                          param: 'orderWidth'
                        })}],"change":function($event){return _vm.$store.dispatch('config/CONFIG_SET_WIDTH', _vm.orderWidth)}}}),_c('label',{staticClass:"error"},[_c('error-label',{attrs:{"min":_vm.minWidth,"max":_vm.maxWidth,"label":_vm.i18n['VALIDATE'][errors[0]]}})],1)]}}],null,true)}),_vm._v(" - "+_vm._s(_vm.i18n.OPTIONS.H)+" "),_c('validation-provider',{attrs:{"rules":("required|number_between:" + _vm.minLength + "," + _vm.maxLength)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.orderLength),expression:"orderLength"}],staticClass:"size__field",attrs:{"type":"number","step":"1"},domProps:{"value":(_vm.orderLength)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.orderLength=$event.target.value},function($event){return _vm.check({
                          e: $event,
                          max: _vm.maxLength,
                          min: _vm.minLength,
                          param: 'orderLength'
                        })}],"change":function($event){return _vm.$store.dispatch(
                          'config/CONFIG_SET_HEIGHT',
                          _vm.orderLength
                        )}}}),_c('label',{staticClass:"error"},[_c('error-label',{attrs:{"min":_vm.minLength,"max":_vm.maxLength,"label":_vm.i18n['VALIDATE'][errors[0]]}})],1)]}}],null,true)}),_vm._v(" "+_vm._s(_vm.i18n.OPTIONS.MM)+" ")],1)]),_c('div',{staticClass:"size"},[(_vm.shape.glass_left && _vm.shape.glass_right)?_c('div',{staticClass:"size__title"},[_vm._v(" Бічні панелі ")]):(_vm.shape.glass_left || _vm.shape.glass_right)?_c('div',{staticClass:"size__title"},[_vm._v(" Бічна панель ")]):_vm._e(),_c('div',{staticClass:"size__text"},[(_vm.shape.glass_left)?_c('div',{staticClass:"size__group"},[_c('span',[_vm._v(_vm._s(_vm.i18n.OPTIONS.L))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.leftWidth),expression:"leftWidth"}],staticClass:"size__field",attrs:{"type":"number","min":"300","step":"50","max":"450"},domProps:{"value":(_vm.leftWidth)},on:{"change":function($event){return _vm.$store.dispatch(
                          'config/CONFIG_SET_LEFT_WIDTH',
                          _vm.leftWidth
                        )},"input":function($event){if($event.target.composing){ return; }_vm.leftWidth=$event.target.value}}})]):_vm._e(),(_vm.shape.glass_right)?_c('div',{staticClass:"size__group"},[_c('span',[_vm._v(_vm._s(_vm.i18n.OPTIONS.R))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.rightWidth),expression:"rightWidth"}],staticClass:"size__field",attrs:{"type":"number","min":"300","step":"50","max":"450"},domProps:{"value":(_vm.rightWidth)},on:{"change":function($event){return _vm.$store.dispatch(
                          'config/CONFIG_SET_RIGHT_WIDTH',
                          _vm.rightWidth
                        )},"input":function($event){if($event.target.composing){ return; }_vm.rightWidth=$event.target.value}}}),_c('span',[_vm._v(_vm._s(_vm.i18n.OPTIONS.MM))])]):_vm._e()])]),(_vm.shape.glass_top)?_c('div',{staticClass:"size"},[_c('div',{staticClass:"size__title"},[_vm._v(_vm._s(_vm.i18n.OPTIONS.FRAME))]),_c('div',{staticClass:"size__text"},[_c('div',{staticClass:"size__lab"},[_vm._v(_vm._s(_vm.i18n.OPTIONS.HEIGHT))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.topHeight),expression:"topHeight"}],staticClass:"size__field size__field--full",attrs:{"type":"number","min":"300","step":"50","max":"600"},domProps:{"value":(_vm.topHeight)},on:{"change":function($event){return _vm.$store.dispatch(
                        'config/CONFIG_SET_TOP_HEIGHT',
                        _vm.topHeight
                      )},"input":function($event){if($event.target.composing){ return; }_vm.topHeight=$event.target.value}}})])]):_vm._e(),_c('div',{staticClass:"additionaly",domProps:{"innerHTML":_vm._s(_vm.optionsDescription)}})])])]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }