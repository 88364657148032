<template>
  <svg:pattern
    id="glass-pattern"
    :x="patternX"
    :y="0"
    :width="doorGlassWidth"
    :height="doorGlassHeight"
    patternUnits="userSpaceOnUse"
  >
    <svg:image
      :x="glassX"
      :y="glassY"
      ref="glass"
      :href="doorGlassSrc"
      :width="doorGlassWidth"
      :height="doorGlassHeight"
    />
  </svg:pattern>
</template>

<script>
import { getBase64FromPNG } from '@/utils/picture';

export default {
  props: [
    'model',
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorRightWidth',
    'doorTopHeight',
    'doorGlassImage',
    'doorGlassWidth',
    'doorGlassHeight',
    'customGlass'
  ],
  data() {
    return {
      doorGlassSrc:
        'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
    };
  },
  computed: {
    patternX() {
      let result = 0;
      if (this.model.substr(0, 1) === 'c') {
        if (this.model === 'c04') {
          result = '100%';
        }
        if (
          this.doorTopHeight === 0 &&
          this.doorLeftWidth === 0 &&
          this.doorRightWidth === 0
        ) {
          if (this.model === 'c07') {
            result = 0;
          } else {
            result = '50%';
          }
        }
        if (
          this.doorTopHeight === 0 &&
          this.doorLeftWidth === 0 &&
          this.doorRightWidth > 0
        ) {
          if (this.model === 'c03' || this.model === 'c08') {
            result = '50%';
          } else {
            result = 0;
          }
        }
        if (
          this.doorTopHeight > 0 &&
          this.doorLeftWidth === 0 &&
          this.doorRightWidth > 0
        ) {
          if (this.model === 'c03') {
            result = '100%';
          } else {
            result = 0;
          }
        }
        if (
          this.doorTopHeight > 0 &&
          this.doorLeftWidth > 0 &&
          this.doorRightWidth > 0
        ) {
          result = '100%';
        }
      }
      if (this.model === 'c20n') {
        result = 0;
      }
      return result;
    },
    glassX() {
      if (parseInt(this.doorGlassWidth, 10) === 400) {
        return 0;
      }
      return (this.doorWidth - this.doorGlassWidth) / 2;
    },
    glassY() {
      if (parseInt(this.doorGlassHeight, 10) === 400) {
        return 0;
      }
      return (this.doorHeight - this.doorGlassHeight) / 2;
    }
  },
  methods: {
    async loadDoorGlass() {
      try {
        this.doorGlassSrc = await getBase64FromPNG({
          image: this.doorGlassImage,
          w: this.doorGlassWidth,
          h: this.doorGlassHeight
        });
      } catch (e) {
        //
      }
    }
  },
  mounted() {
    this.loadDoorGlass();
  }
};
</script>
