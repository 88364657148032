<template>
  <g>
    <svg:style>
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8,
      .cls-9 {
        stroke: #aeaeae;
        stroke-width: 0.25px;
      }

      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8,
      .cls-9 {
        stroke-linecap: round;
        stroke-linejoin: round;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#inox-gradient-2);
      }

      .cls-6 {
        fill: url(#inox-gradient-3);
      }

      .cls-7 {
        fill: url(#glass-pattern);
      }

      .cls-8 {
        fill: url(#glass-pattern);
      }

      .cls-9 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient
      id="handle-gradient"
      :x1="doorLeftWidth + 11.33"
      :y1="doorTopHeight1 + 150.96"
      :x2="doorLeftWidth + 17.01"
      :y2="doorTopHeight1 + 150.96"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.01" stop-color="#babbbc" />
      <stop offset="0.29" stop-color="#97999d" />
      <stop offset="0.65" stop-color="#bdbdc1" />
      <stop offset="0.87" stop-color="#9d9fa2" />
      <stop offset="1" stop-color="#a6a7aa" />
      <stop offset="1" stop-color="#c2c5c9" />
    </linearGradient>
    <linearGradient
      id="inox-gradient"
      :x1="doorLeftWidth + 70.94"
      :y1="doorTopHeight1 + 80.54"
      :x2="doorLeftWidth + 119.61"
      :y2="doorTopHeight1 + 80.54"
      xlink:href="#handle-gradient"
    />
    <linearGradient
      id="inox-gradient-2"
      data-name="inox-gradient"
      :x1="doorLeftWidth + 70.94"
      :y1="doorTopHeight1 + 151.32"
      :x2="doorLeftWidth + 119.61"
      :y2="doorTopHeight1 + 151.32"
      xlink:href="#handle-gradient"
    />
    <linearGradient
      id="inox-gradient-3"
      data-name="inox-gradient"
      :x1="doorLeftWidth + 70.94"
      :y1="doorTopHeight1 + 221.99"
      :x2="doorLeftWidth + 119.61"
      :y2="doorTopHeight1 + 221.99"
      xlink:href="#handle-gradient"
    />
    <linearGradient
      id="glass-gradient"
      :x1="doorLeftWidth + 38.37"
      :y1="doorTopHeight1 + 80.57"
      :x2="doorLeftWidth + 70.93"
      :y2="doorTopHeight1 + 80.57"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#f4f4f5" />
      <stop offset="0.5" stop-color="#dde7e6" />
      <stop offset="1" stop-color="#d1cece" />
    </linearGradient>
    <linearGradient
      id="glass-gradient-2"
      data-name="glass-gradient"
      :x1="doorLeftWidth + 38.37"
      :y1="doorTopHeight1 + 151.32"
      :x2="doorLeftWidth + 70.93"
      :y2="doorTopHeight1 + 151.32"
      xlink:href="#glass-gradient"
    />
    <linearGradient
      id="glass-gradient-3"
      data-name="glass-gradient"
      :x1="doorLeftWidth + 38.37"
      :y1="doorTopHeight1 + 222.07"
      :x2="doorLeftWidth + 70.93"
      :y2="doorTopHeight1 + 222.07"
      xlink:href="#glass-gradient"
    />
    <g id="S15">
      <rect
        id="inox"
        v-if="showInox"
        class="cls-4"
        :x="doorLeftWidth + 70.94"
        :y="doorTopHeight1 + 64.24"
        :width="inoxW"
        height="32.61"
      />
      <rect
        id="inox-2"
        v-if="showInox"
        data-name="inox"
        class="cls-5"
        :x="doorLeftWidth + 70.94"
        :y="doorTopHeight1 + 135.04"
        :width="inoxW"
        height="32.56"
      />
      <rect
        id="inox-3"
        v-if="showInox"
        data-name="inox"
        class="cls-6"
        :x="doorLeftWidth + 70.94"
        :y="doorTopHeight1 + 205.79"
        :width="inoxW"
        height="32.41"
      />
      <rect
        id="glass"
        class="cls-7"
        :x="doorLeftWidth + 38.37"
        :y="doorTopHeight1 + 64.29"
        width="32.56"
        height="32.56"
      />
      <rect
        id="glass-2"
        data-name="glass"
        class="cls-8"
        :x="doorLeftWidth + 38.37"
        :y="doorTopHeight1 + 135.04"
        width="32.56"
        height="32.56"
      />
      <rect
        id="glass-3"
        data-name="glass"
        class="cls-9"
        :x="doorLeftWidth + 38.37"
        :y="doorTopHeight1 + 205.79"
        width="32.56"
        height="32.56"
      />
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg'
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4
    };
  },
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth / 2 - 71;
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight / 2 - 153;
    },
    inoxW() {
      const result = this.showBg
        ? this.doorWidth - 2 * this.inoxOffset - 49
        : this.doorWidth - 2 * this.leafOffset - 65;
      console.log(result);
      return result;
    }
  }
};
</script>
