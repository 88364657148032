var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overlay"},[_c('page-title',[_vm._v(_vm._s(_vm.i18n.MENU.furniture))]),(_vm.model === '')?_c('div',{staticClass:"page-width"},[_c('div',{staticClass:"furniture__empty"},[_vm._v(" "+_vm._s(_vm.i18n.FURNITURE.SELECT_MODEL)+" ")])]):_vm._e(),(_vm.model)?_c('div',{staticClass:"page-width"},[_c('div',{staticClass:"furniture"},[(_vm.items === null)?_c('div',{staticClass:"furniture__empty"},[_vm._v(" "+_vm._s(_vm.i18n.FURNITURE.EMPTY_FURNITURE)+" ")]):_vm._e(),_c('div',{class:[
          'accordion__item',
          { 'accordion__item--opened': _vm.showFurniture }
        ]},[_c('button',{staticClass:"accordion__heading",on:{"click":function($event){$event.preventDefault();return _vm.toggleFurniture($event)}}},[_c('div',{staticClass:"accordion__text"},[_vm._v(_vm._s(_vm.items.title))])]),_c('div',{class:[
            'accordion__content',
            { 'accordion__content--active': _vm.showFurniture }
          ]},[_c('div',{staticClass:"page-width"},[_c('div',[_c('div',{staticClass:"options "},[(_vm.furnitureItems.length === 0)?_c('div',{staticClass:"accordion__empty"},[_vm._v(" "+_vm._s(_vm.i18n.OPTIONS.EMPTY_CONSTRUCTION)+" ")]):_vm._e(),_c('div',{staticClass:"categories"},_vm._l((_vm.furnitureItems),function(item,index){return _c('div',{key:("options__" + (item.ord)),staticClass:"categories__main"},[_c('label',{class:[
                        'options__item',
                        'categories__item',
                        {
                          'options__item--current':
                            item.id ===
                            (_vm.house === 'inside'
                              ? _vm.furnitureInside.id
                              : _vm.furniture.id)
                        },
                        {
                          'options__item--active': item.active === '1'
                        }
                      ]},[_c('div',{staticClass:"categories__present"},[_c('div',[_c('span',{staticClass:"options__bg"},[_c('img',{staticClass:"options__img",attrs:{"src":item.image_png}})]),_c('div',{staticClass:"options__title"},[_vm._v(_vm._s(item.title))])])]),_c('div',{staticClass:"radio"},_vm._l((item.subitems),function(subitem,index){return _c('div',{key:("option_" + (subitem.id)),staticClass:"option"},[_c('p',{class:("option__" + index)},[_c('input',{attrs:{"type":"radio","id":("furniture_" + (subitem.id)),"disabled":_vm.house === 'inside',"name":"furniture"},domProps:{"value":subitem.id,"checked":subitem.id === _vm.currentId},on:{"change":function($event){$event.preventDefault();return _vm.setFurniture(
                                  item,
                                  subitem,
                                  subitem.id === _vm.currentId
                                )}}}),_c('label',{attrs:{"for":("furniture_" + (subitem.id))}},[_vm._v(_vm._s(subitem.title))])])])}),0)]),_c('hr',{class:("categories__hr hr__" + (_vm.furnitureItems.length - 1 === index ? 'last' : index))})])}),0)])])])])])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }