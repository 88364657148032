<template>
  <div class="model">
    <div class="model__empty" v-if="items.length === 0">
      {{ i18n.MODEL.NO_MODELS }}
    </div>
    <div
      :class="[
        'model__item',
        { 'model__item--current': item.id === model.id },
        { 'model__item--active': item.active === '1' }
      ]"
      v-for="(item, idx) in items"
      @click.prevent="
        setModel(item, item.id === model.id || item.active === '0')
      "
      :key="`model__${idx}`"
    >
      <img :src="item.image_svg" class="model__img" :alt="item.title" />
      <div class="model__title">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { API } from '@/constants/api';
import { setCode } from '@/utils/code';

export default {
  props: ['series'],
  data() {
    return {
      items: []
    };
  },
  methods: {
    async setModel(item, isActive) {
      if (isActive) {
        return false;
      }
      let response;
      const {
        shape,
        // model,
        glass,
        color,
        furnitureSub,
        options,
        inox,
        view,
        mirrored,
        safeglass,
        black
      } = this;
      // Check if furniture is available for this model
      let furnitureResponse = await axios.get(API.GET_FURNITURE, {
        params: {
          model: item.id
        }
      });

      // If furniture is not available, set default furniture
      if (
        !furnitureResponse.data[0].items.some(f => f.id === furnitureSub.item)
      ) {
        let furnitureDefault = await axios.get(API.GET_DEFAULT);
        await this.$store.dispatch(
          'config/CONFIG_SET_FURNITURE',
          furnitureDefault.data.furniture
        );
      }
      const moldingView = inox?.url.includes('molding')
        ? inox?.url.split('-')[1]
        : null;
      try {
        response = await axios.get(API.GET_CODE_FROM_CONFIG, {
          params: {
            shape: shape.id,
            model: item.id,
            glass: glass.id,
            colour_outside: color.outside.id,
            colour_inside: color.inside.id,
            furniture: furnitureSub.item,
            options: options.id,
            inox: inox.id,
            molding: moldingView,
            view: view.id,
            mirrored: mirrored.id,
            safeglass: safeglass.id,
            first_inox_if_no_submodel: 'true',
            first_glass_if_no_model: 'true',
            black
          }
        });
      } catch (e) {
        console.error(e);
      }
      await setCode({
        code: response.data.code,
        store: this.$store,
        router: this.$router
      });
      await this.parseCode({ code: response.data.code });
      await this.$store.dispatch('config/CONFIG_SET_MODEL', item);
      await this.$store.dispatch('config/CONFIG_ALLOW_CALCULATE');
    },
    parseCode({ code }) {
      return axios
        .get(API.GET_CONFIG_FROM_CODE, {
          params: {
            code
          }
        })
        .then(async response => {
          if (response.data?.glass) {
            await this.$store.dispatch(
              'config/CONFIG_SET_GLASS',
              response.data.glass
            );
          }
          if (response.data?.inox) {
            await this.$store.dispatch(
              'config/CONFIG_SET_INOX',
              response.data.inox
            );
          }
        });
    }
  },
  computed: {
    ...mapGetters('session', ['i18n']),
    ...mapGetters('config', [
      'shape',
      'model',
      'glass',
      'color',
      'furnitureSub',
      'options',
      'inox',
      'view',
      'mirrored',
      'safeglass',
      'black'
    ])
  },
  created() {
    axios
      .get(API.GET_MODELS + '?series=' + this.series)
      .then(response => (this.items = response.data));
  }
};
</script>

<style lang="scss">
.model {
  max-width: 100vw;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
  @include miw($xl) {
    max-width: inherit;
    flex-wrap: wrap;
    overflow-x: visible;
  }
  &__empty {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  }
  &__item {
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 20px 0 20px;
    opacity: 0.5;
    border: solid #5c646b {
      width: 1px 0 0 1px;
    }
    transition: background-color 0.25s linear;
    flex: 1 0 50%;
    max-width: 50%;
    &:nth-child(odd) {
      border-left-width: 0;
    }
    &--active {
      cursor: pointer;
      opacity: 1;
    }
    @include miw($xl) {
      &--active:hover {
        background: #c70552;
      }
    }
    &--current {
      background: #c70552;
      cursor: default;
    }
  }
  &__img {
    display: block;
    margin: 0 auto;
    max-width: 75%;
  }
  &__title {
    margin: 15px 0 0;
    font: 15px/18px 'ProximaNovaRegular', sans-serif;
    color: #ffffff;
  }
}
</style>
