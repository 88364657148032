<template>
  <div v-if="price !== ''" class="price-label">
    {{ price }} {{ currency.symbol }}
  </div>
</template>

<script>
import axios from 'axios';
import { API } from '@/constants/api';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('config', [
      'shape',
      'model',
      'glass',
      'color',
      'furnitureSub',
      'options',
      'inox',
      'safeglass',
      'view',
      'black',
      'dimensions',
      'house',
      'colorThickness'
    ]),
    ...mapGetters('session', ['currencies', 'currency']),
    totalWidth() {
      if (this.shape.glass_left && this.shape.glass_right) {
        return (
          this.dimensions.width +
          this.dimensions.leftWidth +
          this.dimensions.rightWidth
        );
      }
      if (this.shape.glass_left) {
        return this.dimensions.width + this.dimensions.leftWidth;
      }
      if (this.shape.glass_right) {
        return this.dimensions.width + this.dimensions.rightWidth;
      }
      return this.dimensions.width;
    },
    totalHeight() {
      return this.dimensions.height;
    },
    sideOfShape() {
      if (this.shape.glass_left) {
        return 'left';
      }
      if (this.shape.glass_right) {
        return 'right';
      }
      if (this.shape.glass_top) {
        return 'top';
      }
      return null;
    },
    colorSubIdInside() {
      const width = this.totalWidth;
      const height = this.totalHeight;
      let side = 'inside';
      if (
        this.color[side].category === '2' ||
        this.color[side].subitems[this.colorThickness[side]] === undefined ||
        this.color[side].subitems[this.colorThickness[side]].length === 0
      ) {
        return null;
      }
      for (
        let i = 0;
        i < this.color[side].subitems[this.colorThickness[side]].length;
        i++
      ) {
        if (
          width <=
            this.color[side].subitems[this.colorThickness[side]][i]
              .price_width_stop &&
          width >=
            this.color[side].subitems[this.colorThickness[side]][i]
              .price_width &&
          height <=
            this.color[side].subitems[this.colorThickness[side]][i].price_height
        ) {
          this.setColorSubIdInside(
            this.color[side].subitems[this.colorThickness[side]][i].id
          );
          return this.color[side].subitems[this.colorThickness[side]][i].id;
        }
      }
      return null;
    },
    colorSubIdOutside() {
      const width = this.totalWidth;
      const height = this.totalHeight;
      let side = 'outside';
      if (
        this.color[side].category === '2' ||
        this.color[side].subitems[this.colorThickness[side]] === undefined ||
        this.color[side].subitems[this.colorThickness[side]].length === 0
      ) {
        return null;
      }
      for (
        let i = 0;
        i < this.color[side].subitems[this.colorThickness[side]].length;
        i++
      ) {
        if (
          width <=
            this.color[side].subitems[this.colorThickness[side]][i]
              .price_width_stop &&
          width >=
            this.color[side].subitems[this.colorThickness[side]][i]
              .price_width &&
          height <=
            this.color[side].subitems[this.colorThickness[side]][i].price_height
        ) {
          this.setColorSubIdOutside(
            this.color[side].subitems[this.colorThickness[side]][i].id
          );
          return this.color[side].subitems[this.colorThickness[side]][i].id;
        }
      }
      return null;
    },
    formSubId() {
      const width = this.totalWidth;
      const height = this.totalHeight;
      let subitems = this.shape.subitems;
      if (subitems === undefined || subitems.length === 0) {
        return null;
      }
      subitems = subitems[this.sideOfShape];

      for (let i = 0; i < subitems.length; i++) {
        if (
          this.sideOfShape !== 'top' &&
          width <= subitems[i].stop_width &&
          width >= subitems[i].start_width
        ) {
          this.setFormSubId(subitems[i].id);
          return subitems[i].id;
        }
        if (
          this.sideOfShape === 'top' &&
          height <= subitems[i].stop_height &&
          height >= subitems[i].start_height
        ) {
          this.setFormSubId(subitems[i].id);
          return subitems[i].id;
        }
      }
      return null;
    }
  },
  data() {
    return {
      price: ''
    };
  },
  methods: {
    setFormSubId(id) {
      this.$store.dispatch('config/CONFIG_SET_FORM_SUB_ID', id);
    },
    setColorSubIdInside(id) {
      this.$store.dispatch('config/CONFIG_SET_COLOR_INSIDE_SUB_ID', id);
    },
    setColorSubIdOutside(id) {
      this.$store.dispatch('config/CONFIG_SET_COLOR_OUTSIDE_SUB_ID', id);
    },

    getPrice() {
      const {
        shape,
        model,
        glass,
        color,
        furnitureSub,
        options,
        inox,
        safeglass,
        view,
        black,
        colorSubIdInside,
        colorSubIdOutside,
        formSubId
      } = this;
      const moldingView = inox?.url.includes('molding')
        ? inox?.url.split('-')[1]
        : null;
      axios
        .get(API.GET_PRICE, {
          params: {
            shape: shape.id,
            model: model.id,
            glass: glass.id,
            safeglass: safeglass.id,
            colour_outside: color.outside.id,
            colour_inside: color.inside.id,
            furniture: furnitureSub.id,
            options: options.id,
            inox: inox.id,
            molding: moldingView,
            view: view.id,
            currency_name: this.currency.name,
            black,
            formSubId: formSubId,
            colorInsideSubId: colorSubIdInside,
            colorOutsideSubId: colorSubIdOutside
          }
        })
        .then(
          response =>
            (this.price = response.data.price
              ?.toString()
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1 '))
        );
    }
  },
  watch: {
    formSubId: {
      handler() {
        this.getPrice();
      }
    },
    colorThickness: {
      handler() {
        this.getPrice();
      }
    },
    colorSubIdInside: {
      handler() {
        this.getPrice();
      }
    },
    colorSubIdOutside: {
      handler() {
        this.getPrice();
      }
    },
    totalWidth: {
      handler() {
        this.getPrice();
      }
    },
    totalHeight: {
      handler() {
        this.getPrice();
      }
    }
  },
  created() {
    this.getPrice();
  }
};
</script>

<style lang="scss">
.price-label {
  position: absolute;
  right: 20px;
  top: 75px;
  font: 26px/30px 'ProximaNovaBold', sans-serif;
  padding: 15px;
  color: #f8f8f8;
  background: rgba(39, 44, 48, 0.8);
  @include miw($xl) {
    top: 20px;
  }
}
</style>
